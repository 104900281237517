import * as actionTypes from './actionTypes';
import { CommunicationActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ListResults } from '../../common/List/List';
import { Communication } from '../../domain/Communication';

export type CommunicationStateType = {
  communicationCreateLoading: boolean;
  communicationCreateError: HttpError;
  communicationCreateSuccess: boolean;
  communicationListUpdateNeeded: boolean;
  communicationList: ListResults<Communication> | null;
  communicationListLoading: boolean;
  communicationListError: HttpError;
};

export type CommunicationActionType = CommunicationStateType & {
  type: CommunicationActionTypes;
};

export const initialState: CommunicationStateType = {
  communicationCreateLoading: false,
  communicationCreateError: null,
  communicationCreateSuccess: false,
  communicationListUpdateNeeded: false,
  communicationList: null,
  communicationListLoading: true,
  communicationListError: null,
};

const fetchCommunicationListStart = (
  state: CommunicationStateType,
): CommunicationStateType => ({
  ...state,
  communicationListLoading: true,
  // communicationCreateSuccess: false,
});

const fetchCommunicationListSuccess = (
  state: CommunicationStateType,
  action: CommunicationActionType,
): CommunicationStateType => ({
  ...state,
  communicationList: action.communicationList,
  communicationListLoading: false,
  communicationListError: null,
  communicationListUpdateNeeded: false,
});

const fetchCommunicationListFail = (
  state: CommunicationStateType,
  action: CommunicationActionType,
): CommunicationStateType => ({
  ...state,
  communicationListError: action.communicationListError,
  communicationListLoading: false,
});

const createCommunicationStart = (
  state: CommunicationStateType,
): CommunicationStateType => ({
  ...state,
  communicationCreateLoading: true,
});

const createCommunicationSuccess = (
  state: CommunicationStateType,
): CommunicationStateType => ({
  ...state,
  communicationCreateLoading: false,
  communicationCreateError: null,
  communicationCreateSuccess: true,
  communicationListUpdateNeeded: true,
});

const createCommunicationFail = (
  state: CommunicationStateType,
  action: CommunicationActionType,
): CommunicationStateType => ({
  ...state,
  communicationCreateLoading: false,
  communicationCreateError: action.communicationCreateError,
});

const resetCreatedCommunicationStore = (
  state: CommunicationStateType,
): CommunicationStateType => ({
  ...state,
  communicationCreateError: null,
  communicationCreateSuccess: false,
  communicationCreateLoading: false,
});

const resetCommunicationStore = (): CommunicationStateType => ({
  ...initialState,
});

const completelyResetCommunicationStore = (): CommunicationStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: CommunicationActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_COMMUNICATION_LIST_START:
      return fetchCommunicationListStart(state);
    case actionTypes.FETCH_COMMUNICATION_LIST_SUCCESS:
      return fetchCommunicationListSuccess(state, action);
    case actionTypes.FETCH_COMMUNICATION_LIST_FAIL:
      return fetchCommunicationListFail(state, action);
    case actionTypes.CREATE_COMMUNICATION_START:
      return createCommunicationStart(state);
    case actionTypes.CREATE_COMMUNICATION_SUCCESS:
      return createCommunicationSuccess(state);
    case actionTypes.CREATE_COMMUNICATION_FAIL:
      return createCommunicationFail(state, action);
    case actionTypes.RESET_CREATED_COMMUNICATION_STORE:
      return resetCreatedCommunicationStore(state);
    case actionTypes.RESET_COMMUNICATION_STORE:
      return resetCommunicationStore();
    case actionTypes.LOGOUT:
      return completelyResetCommunicationStore();
    default:
      return state;
  }
};

export default reducer;
