import React from 'react';
import { Provider } from 'react-redux';
import {
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
  Store,
} from 'redux';
import thunk from 'redux-thunk';
import authReducer, { AuthStateType } from '../../store/auth/reducer';
import userReducer, { UserStateType } from '../../store/user/reducer';
import balanceHistoryReducer, {
  BalanceHistoryStateType,
} from '../../store/balance-history/reducer';
import languageReducer, {
  LanguageStateType,
} from '../../store/language/reducer';
import translationReducer, {
  TranslationStateType,
} from '../../store/translation/reducer';
import paymentReducer, { PaymentStateType } from '../../store/payment/reducer';
import certificateTemplateReducer, {
  CertificateTemplatesStateType,
} from '../../store/certificate-template/reducer';
import templateReducer, {
  TemplatesStateType,
} from '../../store/template/reducer';

import instance from '../Axios/axios-instance';
import * as authService from '../../store/auth/actions';
import participantReducer, {
  ParticipantStateType,
} from '../../store/participant/reducer';
import attributeValueReducer, {
  AttributeValueStateType,
} from '../../store/attribute-value/reducer';
import certificateReducer, {
  CertificateStateType,
} from '../../store/certificate/reducer';
import certificateSectionReducer, {
  CertificateSectionStateType,
} from '../../store/certificate-section/reducer';
import creditPackageReducer, {
  CreditPackageStateType,
} from '../../store/creditPackage/reducer';
import profileReducer, { ProfileStateType } from '../../store/profile/reducer';
import participantCertificateSectionReducer, {
  ParticipantCertificateSectionStateType,
} from '../../store/participant-certificate-section/reducer';
import textTemplateReducer, {
  TextTemplateStateType,
} from '../../store/text-template/reducer';
import communicationReducer, {
  CommunicationStateType,
} from '../../store/communication/reducer';
import companyReducer, { CompanyStateType } from '../../store/company/reducer';
import organiserInfoReducer, {
  OrganiserInfoStateType,
} from '../../store/organiser-info/reducer';
import companyInvoiceInfoReducer, {
  CompanyInvoiceInfoStateType,
} from '../../store/company-invoice-info/reducer';

export type StoreState = {
  auth: AuthStateType;
  user: UserStateType;
  translation: TranslationStateType;
  language: LanguageStateType;
  balanceHistory: BalanceHistoryStateType;
  payment: PaymentStateType;
  certificateTemplate: CertificateTemplatesStateType;
  template: TemplatesStateType;
  participant: ParticipantStateType;
  attributeValue: AttributeValueStateType;
  certificate: CertificateStateType;
  certificateSection: CertificateSectionStateType;
  creditPackage: CreditPackageStateType;
  profile: ProfileStateType;
  participantCertificateSection: ParticipantCertificateSectionStateType;
  textTemplate: TextTemplateStateType;
  communication: CommunicationStateType;
  company: CompanyStateType;
  organiserInfo: OrganiserInfoStateType;
  companyInvoiceInfo: CompanyInvoiceInfoStateType;
};

type Props = {
  children?: any;
};

const rootReducer = combineReducers<StoreState>({
  auth: authReducer,
  user: userReducer,
  translation: translationReducer,
  language: languageReducer,
  balanceHistory: balanceHistoryReducer,
  payment: paymentReducer,
  certificateTemplate: certificateTemplateReducer,
  template: templateReducer,
  participant: participantReducer,
  attributeValue: attributeValueReducer,
  certificate: certificateReducer,
  certificateSection: certificateSectionReducer,
  creditPackage: creditPackageReducer,
  profile: profileReducer,
  participantCertificateSection: participantCertificateSectionReducer,
  textTemplate: textTemplateReducer,
  communication: communicationReducer,
  company: companyReducer,
  organiserInfo: organiserInfoReducer,
  companyInvoiceInfo: companyInvoiceInfoReducer,
});

export const composeEnhancers =
  (window && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

export const store: Store<any, any> = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk)),
);

const { dispatch } = store;
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response.status === 401 &&
      error.response.config.url !== '/token'
    ) {
      dispatch(authService.logout());
    }

    return Promise.reject(error);
  },
);

const StoreProvider = ({ children }: Props) => (
  <Provider store={store}>{children}</Provider>
);

export default StoreProvider;
