import * as actionTypes from './actionTypes';
import { CompanyActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { UserCompanyRole } from '../../domain/UserCompanyRole';

export type CompanyStateType = {
  selectedCompany: number | null;
  companySelectLoading: boolean;
  companySelectSuccess: boolean;
  companySelectError: HttpError;
  isUpdateNeeded: boolean;
  inviteToCompanyLoading: boolean;
  inviteToCompanySuccess: boolean;
  inviteToCompanyError: HttpError;
  allCompanyUsersLoading: boolean;
  allCompanyUsers: UserCompanyRole[];
  allCompanyUsersError: HttpError;
  removeUserFromCompanyLoading: boolean;
  removeUserFromCompanySuccess: boolean;
  removeUserFromCompanyError: HttpError;
};

export type CompanyActionType = CompanyStateType & {
  type: CompanyActionTypes;
};

export const initialState: CompanyStateType = {
  selectedCompany: null,
  companySelectLoading: false,
  companySelectSuccess: false,
  companySelectError: null,
  isUpdateNeeded: false,
  inviteToCompanyLoading: false,
  inviteToCompanySuccess: false,
  inviteToCompanyError: null,
  allCompanyUsersLoading: false,
  allCompanyUsers: [],
  allCompanyUsersError: null,
  removeUserFromCompanyLoading: false,
  removeUserFromCompanySuccess: false,
  removeUserFromCompanyError: null,
};

const setSelectedCompany = (
  state: CompanyStateType,
  action: CompanyActionType,
): CompanyStateType => ({
  ...state,
  selectedCompany: action.selectedCompany,
});

const selectCompanyStart = (state: CompanyStateType): CompanyStateType => ({
  ...state,
  companySelectLoading: true,
});

const selectCompanySuccess = (
  state: CompanyStateType,
  action: CompanyActionType,
): CompanyStateType => ({
  ...state,
  selectedCompany: action.selectedCompany,
  companySelectLoading: false,
  companySelectSuccess: true,
  companySelectError: null,
  isUpdateNeeded: true,
});

const selectCompanyFail = (
  state: CompanyStateType,
  action: CompanyActionType,
): CompanyStateType => ({
  ...state,
  companySelectError: action.companySelectError,
  companySelectLoading: false,
});

const inviteToCompanyStart = (state: CompanyStateType): CompanyStateType => ({
  ...state,
  inviteToCompanyLoading: true,
});

const inviteToCompanySuccess = (state: CompanyStateType): CompanyStateType => ({
  ...state,
  inviteToCompanyLoading: false,
  inviteToCompanySuccess: true,
  inviteToCompanyError: null,
});

const inviteToCompanyFail = (
  state: CompanyStateType,
  action: CompanyActionType,
): CompanyStateType => ({
  ...state,
  inviteToCompanyError: action.inviteToCompanyError,
  inviteToCompanyLoading: false,
});

const fetchAllCompanyUsersStart = (
  state: CompanyStateType,
): CompanyStateType => ({
  ...state,
  inviteToCompanySuccess: false,
  removeUserFromCompanySuccess: false,
  allCompanyUsersLoading: true,
});

const fetchAllCompanyUsersSuccess = (
  state: CompanyStateType,
  action: CompanyActionType,
): CompanyStateType => ({
  ...state,
  allCompanyUsersLoading: false,
  allCompanyUsers: action.allCompanyUsers,
  allCompanyUsersError: null,
});

const fetchAllCompanyUsersFail = (
  state: CompanyStateType,
  action: CompanyActionType,
): CompanyStateType => ({
  ...state,
  allCompanyUsersError: action.allCompanyUsersError,
  allCompanyUsersLoading: false,
});

const removeUserFromCompanyStart = (
  state: CompanyStateType,
): CompanyStateType => ({
  ...state,
  removeUserFromCompanyLoading: false,
});

const removeUserFromCompanySuccess = (
  state: CompanyStateType,
  action: CompanyActionType,
): CompanyStateType => ({
  ...state,
  removeUserFromCompanyLoading: false,
  removeUserFromCompanySuccess: true,
});

const removeUserFromCompanyFail = (
  state: CompanyStateType,
  action: CompanyActionType,
): CompanyStateType => ({
  ...state,
  removeUserFromCompanyLoading: false,
  removeUserFromCompanyError: action.removeUserFromCompanyError,
});

const reducer = (state = initialState, action: CompanyActionType) => {
  switch (action.type) {
    case actionTypes.SET_SELECTED_COMPANY:
      return setSelectedCompany(state, action);
    case actionTypes.SELECT_COMPANY_START:
      return selectCompanyStart(state);
    case actionTypes.SELECT_COMPANY_SUCCESS:
      return selectCompanySuccess(state, action);
    case actionTypes.SELECT_COMPANY_FAIL:
      return selectCompanyFail(state, action);
    case actionTypes.INVITE_TO_COMPANY_START:
      return inviteToCompanyStart(state);
    case actionTypes.INVITE_TO_COMPANY_SUCCESS:
      return inviteToCompanySuccess(state);
    case actionTypes.INVITE_TO_COMPANY_FAIL:
      return inviteToCompanyFail(state, action);
    case actionTypes.FETCH_ALL_COMPANY_USERS_START:
      return fetchAllCompanyUsersStart(state);
    case actionTypes.FETCH_ALL_COMPANY_USERS_SUCCESS:
      return fetchAllCompanyUsersSuccess(state, action);
    case actionTypes.FETCH_ALL_COMPANY_USERS_FAIL:
      return fetchAllCompanyUsersFail(state, action);
    case actionTypes.REMOVE_USER_FROM_COMPANY_START:
      return removeUserFromCompanyStart(state);
    case actionTypes.REMOVE_USER_FROM_COMPANY_SUCCESS:
      return removeUserFromCompanySuccess(state, action);
    case actionTypes.REMOVE_USER_FROM_COMPANY_FAIL:
      return removeUserFromCompanyFail(state, action);
    default:
      return state;
  }
};

export default reducer;
