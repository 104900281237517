import React from 'react';
import styles from './ProfileWithImage.module.scss';
import avatarPlaceholder from '../../assets/avatar_placeholder.png';
import { Link } from 'react-router-dom';

type Props = {
  firstName?: string;
  lastName?: string;
  email?: string;
  profileImageSrc?: string | null;
  link?: string;
};

const ProfileWithImage = ({
  firstName,
  lastName,
  email,
  profileImageSrc,
  link,
}: Props) => {
  const getContainer = () => {
    return (
      <>
        <div className={styles.container}>
          <img
            className={styles.image}
            src={profileImageSrc ?? avatarPlaceholder}
          />

          <div className={styles.content}>
            <div className={styles.name}>
              {firstName ?? ''} {lastName ?? ''}
            </div>
            <div className={styles.email}>{email ?? ''}</div>
          </div>
        </div>
      </>
    );
  };

  if (link) {
    return <Link to={link}>{getContainer()}</Link>;
  }

  return getContainer();
};

export default ProfileWithImage;
