import * as actionTypes from './actionTypes';
import { OrganiserInfoActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { OrganiserInfo } from '../../domain/OrganiserInfo';
import { OrganiserDetails } from '../../domain/OrganiserDetails';

export type OrganiserInfoStateType = {
  organiserInfo: OrganiserInfo | null;
  organiserInfoError: HttpError;
  organiserInfoLoading: boolean;
  currentOrganiserInfo: OrganiserInfo | null;
  currentOrganiserInfoError: HttpError;
  currentOrganiserInfoLoading: boolean;
  organiserDetailsLoading: boolean;
  organiserDetails: OrganiserDetails | null;
  organiserDetailsError: HttpError;
  organiserInfoUpdateLoading: boolean;
  organiserInfoUpdateError: HttpError;
  organiserInfoUpdateSuccess: boolean;
  currentOrganiserInfoUpdateLoading: boolean;
  currentOrganiserInfoUpdateError: HttpError;
  currentOrganiserInfoUpdateSuccess: boolean;
};

export type OrganiserInfoActionType = OrganiserInfoStateType & {
  type: OrganiserInfoActionTypes;
  refreshCurrentOrganiserInfo: boolean;
};

export const initialState: OrganiserInfoStateType = {
  organiserInfo: null,
  organiserInfoError: null,
  organiserInfoLoading: false,
  currentOrganiserInfo: null,
  currentOrganiserInfoError: null,
  currentOrganiserInfoLoading: false,
  organiserDetailsLoading: false,
  organiserDetails: null,
  organiserDetailsError: null,
  organiserInfoUpdateError: null,
  organiserInfoUpdateLoading: false,
  organiserInfoUpdateSuccess: false,
  currentOrganiserInfoUpdateError: null,
  currentOrganiserInfoUpdateLoading: false,
  currentOrganiserInfoUpdateSuccess: false,
};

const fetchOrganiserInfoStart = (
  state: OrganiserInfoStateType,
): OrganiserInfoStateType => ({
  ...state,
  organiserInfoLoading: true,
});

const fetchOrganiserInfoSuccess = (
  state: OrganiserInfoStateType,
  action: OrganiserInfoActionType,
): OrganiserInfoStateType => ({
  ...state,
  organiserInfo: action.organiserInfo,
  organiserInfoLoading: false,
});

const fetchOrganiserInfoFail = (
  state: OrganiserInfoStateType,
  action: OrganiserInfoActionType,
): OrganiserInfoStateType => ({
  ...state,
  organiserInfoError: action.organiserInfoError,
  organiserInfoLoading: false,
});

const fetchCurrentOrganiserInfoStart = (
  state: OrganiserInfoStateType,
): OrganiserInfoStateType => ({
  ...state,
  currentOrganiserInfoLoading: true,
});

const fetchCurrentOrganiserInfoSuccess = (
  state: OrganiserInfoStateType,
  action: OrganiserInfoActionType,
): OrganiserInfoStateType => ({
  ...state,
  currentOrganiserInfo: action.currentOrganiserInfo,
  currentOrganiserInfoLoading: false,
});

const fetchCurrentOrganiserInfoFail = (
  state: OrganiserInfoStateType,
  action: OrganiserInfoActionType,
): OrganiserInfoStateType => ({
  ...state,
  currentOrganiserInfoError: action.currentOrganiserInfoError,
  currentOrganiserInfoLoading: false,
});

const fetchOrganiserDetailsStart = (
  state: OrganiserInfoStateType,
): OrganiserInfoStateType => ({
  ...state,
  organiserDetailsLoading: true,
});

const fetchOrganiserDetailsSuccess = (
  state: OrganiserInfoStateType,
  action: OrganiserInfoActionType,
): OrganiserInfoStateType => ({
  ...state,
  organiserDetails: action.organiserDetails,
  organiserDetailsLoading: false,
});

const fetchOrganiserDetailsFail = (
  state: OrganiserInfoStateType,
  action: OrganiserInfoActionType,
): OrganiserInfoStateType => ({
  ...state,
  organiserDetailsError: action.organiserDetailsError,
  organiserDetailsLoading: false,
});

const updateOrganiserInfoStart = (
  state: OrganiserInfoStateType,
): OrganiserInfoStateType => ({
  ...state,
  organiserInfoUpdateLoading: true,
  organiserInfoUpdateSuccess: false,
});

const updateOrganiserInfoSuccess = (
  state: OrganiserInfoStateType,
): OrganiserInfoStateType => ({
  ...state,
  organiserInfoUpdateLoading: false,
  organiserInfoUpdateError: null,
  organiserInfoUpdateSuccess: true,
});

const updateOrganiserInfoFail = (
  state: OrganiserInfoStateType,
  action: OrganiserInfoActionType,
): OrganiserInfoStateType => ({
  ...state,
  organiserInfoUpdateLoading: false,
  organiserInfoUpdateError: action.organiserInfoUpdateError,
});

const updateCurrentOrganiserInfoStart = (
  state: OrganiserInfoStateType,
): OrganiserInfoStateType => ({
  ...state,
  currentOrganiserInfoUpdateLoading: true,
  currentOrganiserInfoUpdateSuccess: false,
});

const updateCurrentOrganiserInfoSuccess = (
  state: OrganiserInfoStateType,
): OrganiserInfoStateType => ({
  ...state,
  currentOrganiserInfoUpdateLoading: false,
  currentOrganiserInfoUpdateError: null,
  currentOrganiserInfoUpdateSuccess: true,
});

const updateCurrentOrganiserInfoFail = (
  state: OrganiserInfoStateType,
  action: OrganiserInfoActionType,
): OrganiserInfoStateType => ({
  ...state,
  currentOrganiserInfoUpdateLoading: false,
  currentOrganiserInfoUpdateError: action.currentOrganiserInfoUpdateError,
});

const resetOrganiserInfoStore = (
  state: OrganiserInfoStateType,
): OrganiserInfoStateType => ({
  ...initialState,
});

const resetUpdateOrganiserInfoStore = (
  state: OrganiserInfoStateType,
): OrganiserInfoStateType => ({
  ...initialState,
  currentOrganiserInfoUpdateLoading: false,
  currentOrganiserInfoUpdateError: null,
  currentOrganiserInfoUpdateSuccess: false,
});

const completelyResetOrganiserInfoStore = (): OrganiserInfoStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: OrganiserInfoActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_ORGANISER_INFO_START:
      return fetchOrganiserInfoStart(state);
    case actionTypes.FETCH_ORGANISER_INFO_SUCCESS:
      return fetchOrganiserInfoSuccess(state, action);
    case actionTypes.FETCH_ORGANISER_INFO_FAIL:
      return fetchOrganiserInfoFail(state, action);
    case actionTypes.FETCH_CURRENT_ORGANISER_INFO_START:
      return fetchCurrentOrganiserInfoStart(state);
    case actionTypes.FETCH_CURRENT_ORGANISER_INFO_SUCCESS:
      return fetchCurrentOrganiserInfoSuccess(state, action);
    case actionTypes.FETCH_CURRENT_ORGANISER_INFO_FAIL:
      return fetchCurrentOrganiserInfoFail(state, action);
    case actionTypes.FETCH_ORGANISER_DETAILS_START:
      return fetchOrganiserDetailsStart(state);
    case actionTypes.FETCH_ORGANISER_DETAILS_SUCCESS:
      return fetchOrganiserDetailsSuccess(state, action);
    case actionTypes.FETCH_ORGANISER_DETAILS_FAIL:
      return fetchOrganiserDetailsFail(state, action);
    case actionTypes.UPDATE_ORGANISER_INFO_START:
      return updateOrganiserInfoStart(state);
    case actionTypes.UPDATE_ORGANISER_INFO_SUCCESS:
      return updateOrganiserInfoSuccess(state);
    case actionTypes.UPDATE_ORGANISER_INFO_FAIL:
      return updateOrganiserInfoFail(state, action);
    case actionTypes.UPDATE_CURRENT_ORGANISER_INFO_START:
      return updateCurrentOrganiserInfoStart(state);
    case actionTypes.UPDATE_CURRENT_ORGANISER_INFO_SUCCESS:
      return updateCurrentOrganiserInfoSuccess(state);
    case actionTypes.UPDATE_CURRENT_ORGANISER_INFO_FAIL:
      return updateCurrentOrganiserInfoFail(state, action);
    case actionTypes.RESET_ORGANISER_INFO_STORE:
      return resetOrganiserInfoStore(state);
    case actionTypes.RESET_UPDATE_ORGANISER_INFO_STORE:
      return resetUpdateOrganiserInfoStore(state);
    case actionTypes.LOGOUT:
      return completelyResetOrganiserInfoStore();
    default:
      return state;
  }
};

export default reducer;
