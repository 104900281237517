export const FETCH_PARTICIPANTS_START = 'FETCH_PARTICIPANTS_START';
export const FETCH_PARTICIPANTS_SUCCESS = 'FETCH_PARTICIPANTS_SUCCESS';
export const FETCH_PARTICIPANTS_FAIL = 'FETCH_PARTICIPANTS_FAIL';

export const FETCH_PARTICIPANT_OVERVIEW_INFO_START =
  'FETCH_PARTICIPANT_OVERVIEW_INFO_START';
export const FETCH_PARTICIPANT_OVERVIEW_INFO_SUCCESS =
  'FETCH_PARTICIPANT_OVERVIEW_INFO_SUCCESS';
export const FETCH_PARTICIPANT_OVERVIEW_INFO_FAIL =
  'FETCH_PARTICIPANT_OVERVIEW_INFO_FAIL';

export const FETCH_PARTICIPANTS_SUGGESTIONS_START =
  'FETCH_PARTICIPANTS_SUGGESTIONS_START';
export const FETCH_PARTICIPANTS_SUGGESTIONS_SUCCESS =
  'FETCH_PARTICIPANTS_SUGGESTIONS_SUCCESS';
export const FETCH_PARTICIPANTS_SUGGESTIONS_FAIL =
  'FETCH_PARTICIPANTS_SUGGESTIONS_FAIL';

export const FETCH_GLOBAL_PARTICIPANTS_SUGGESTIONS_START =
  'FETCH_GLOBAL_PARTICIPANTS_SUGGESTIONS_START';
export const FETCH_GLOBAL_PARTICIPANTS_SUGGESTIONS_SUCCESS =
  'FETCH_GLOBAL_PARTICIPANTS_SUGGESTIONS_SUCCESS';
export const FETCH_GLOBAL_PARTICIPANTS_SUGGESTIONS_FAIL =
  'FETCH_GLOBAL_PARTICIPANTS_SUGGESTIONS_FAIL';

export const CREATE_PARTICIPANT_START = 'CREATE_PARTICIPANT_START';
export const CREATE_PARTICIPANT_SUCCESS = 'CREATE_PARTICIPANT_SUCCESS';
export const CREATE_PARTICIPANT_FAIL = 'CREATE_PARTICIPANT_FAIL';

export const CREATE_PARTICIPANTS_START = 'CREATE_PARTICIPANTS_START';
export const CREATE_PARTICIPANTS_SUCCESS = 'CREATE_PARTICIPANTS_SUCCESS';
export const CREATE_PARTICIPANTS_FAIL = 'CREATE_PARTICIPANTS_FAIL';

export const DELETE_PARTICIPANT_START = 'DELETE_PARTICIPANT_START';
export const DELETE_PARTICIPANT_SUCCESS = 'DELETE_PARTICIPANT_SUCCESS';
export const DELETE_PARTICIPANT_FAIL = 'DELETE_PARTICIPANT_FAIL';

export const UPDATE_PARTICIPANT_START = 'UPDATE_PARTICIPANT_START';
export const UPDATE_PARTICIPANT_SUCCESS = 'UPDATE_PARTICIPANT_SUCCESS';
export const UPDATE_PARTICIPANT_FAIL = 'UPDATE_PARTICIPANT_FAIL';

export const UPDATE_PARTICIPANT_WITH_ATTRIBUTES_START =
  'UPDATE_PARTICIPANT_WITH_ATTRIBUTES_START';
export const UPDATE_PARTICIPANT_WITH_ATTRIBUTES_SUCCESS =
  'UPDATE_PARTICIPANT_WITH_ATTRIBUTES_SUCCESS';
export const UPDATE_PARTICIPANT_WITH_ATTRIBUTES_FAIL =
  'UPDATE_PARTICIPANT_WITH_ATTRIBUTES_FAIL';

export const DELETE_MULTIPLE_PARTICIPANTS_START =
  'DELETE_MULTIPLE_PARTICIPANTS_START';
export const DELETE_MULTIPLE_PARTICIPANTS_SUCCESS =
  'DELETE_MULTIPLE_PARTICIPANTS_SUCCESS';
export const DELETE_MULTIPLE_PARTICIPANTS_FAIL =
  'DELETE_MULTIPLE_PARTICIPANTS_FAIL';

export const DELETE_PARTICIPANTS_CERTIFICATES_STATUSES_START =
  'DELETE_PARTICIPANTS_CERTIFICATES_STATUSES_START';
export const DELETE_PARTICIPANTS_CERTIFICATES_STATUSES_SUCCESS =
  'DELETE_PARTICIPANTS_CERTIFICATES_STATUSES_SUCCESS';
export const DELETE_PARTICIPANTS_CERTIFICATES_STATUSES_FAIL =
  'DELETE_PARTICIPANTS_CERTIFICATES_STATUSES_FAIL';

export const CHANGE_PARTICIPANT_LANGUAGE_START =
  'CHANGE_PARTICIPANT_LANGUAGE_START';
export const CHANGE_PARTICIPANT_LANGUAGE_SUCCESS =
  'CHANGE_PARTICIPANT_LANGUAGE_SUCCESS';
export const CHANGE_PARTICIPANT_LANGUAGE_FAIL =
  'CHANGE_PARTICIPANT_LANGUAGE_FAIL';

export const RESET_DELETED_PARTICIPANTS_CERTIFICATES_STATUSES_STORE =
  'RESET_DELETED_PARTICIPANTS_CERTIFICATES_STATUSES_STORE';
export const RESET_PARTICIPANTS_SUGGESTIONS = 'RESET_PARTICIPANTS_SUGGESTIONS';
export const RESET_GLOBAL_PARTICIPANTS_SUGGESTIONS =
  'RESET_GLOBAL_PARTICIPANTS_SUGGESTIONS';
export const RESET_UPDATED_PARTICIPANT_WITH_ATTRIBUTES_PARTICIPANT =
  'RESET_UPDATED_PARTICIPANT_WITH_ATTRIBUTES_PARTICIPANT';
export const RESET_DELETED_MULTIPLE_PARTICIPANTS_STORE =
  'RESET_DELETED_MULTIPLE_PARTICIPANTS_STORE';
export const RESET_PARTICIPANT_STORE = 'RESET_PARTICIPANT_STORE';
export const RESET_UPDATE_PARTICIPANT_STORE = 'RESET_UPDATE_PARTICIPANT_STORE';

export const SET_SEARCHED_PARTICIPANT = 'SET_SEARCHED_PARTICIPANT';
export const RESET_SEARCHED_PARTICIPANT = 'RESET_SEARCHED_PARTICIPANT';

export const LOGOUT = 'LOGOUT';

export type ParticipantActionTypes =
  | typeof FETCH_PARTICIPANTS_START
  | typeof FETCH_PARTICIPANTS_SUCCESS
  | typeof FETCH_PARTICIPANTS_FAIL
  | typeof FETCH_PARTICIPANT_OVERVIEW_INFO_START
  | typeof FETCH_PARTICIPANT_OVERVIEW_INFO_SUCCESS
  | typeof FETCH_PARTICIPANT_OVERVIEW_INFO_FAIL
  | typeof FETCH_PARTICIPANTS_SUGGESTIONS_START
  | typeof FETCH_PARTICIPANTS_SUGGESTIONS_SUCCESS
  | typeof FETCH_PARTICIPANTS_SUGGESTIONS_FAIL
  | typeof FETCH_GLOBAL_PARTICIPANTS_SUGGESTIONS_START
  | typeof FETCH_GLOBAL_PARTICIPANTS_SUGGESTIONS_SUCCESS
  | typeof FETCH_GLOBAL_PARTICIPANTS_SUGGESTIONS_FAIL
  | typeof UPDATE_PARTICIPANT_START
  | typeof UPDATE_PARTICIPANT_SUCCESS
  | typeof UPDATE_PARTICIPANT_FAIL
  | typeof UPDATE_PARTICIPANT_WITH_ATTRIBUTES_START
  | typeof UPDATE_PARTICIPANT_WITH_ATTRIBUTES_SUCCESS
  | typeof UPDATE_PARTICIPANT_WITH_ATTRIBUTES_FAIL
  | typeof CREATE_PARTICIPANT_START
  | typeof CREATE_PARTICIPANT_SUCCESS
  | typeof CREATE_PARTICIPANT_FAIL
  | typeof CREATE_PARTICIPANTS_START
  | typeof CREATE_PARTICIPANTS_SUCCESS
  | typeof CREATE_PARTICIPANTS_FAIL
  | typeof DELETE_PARTICIPANT_START
  | typeof DELETE_PARTICIPANT_SUCCESS
  | typeof DELETE_PARTICIPANT_FAIL
  | typeof DELETE_MULTIPLE_PARTICIPANTS_START
  | typeof DELETE_MULTIPLE_PARTICIPANTS_SUCCESS
  | typeof DELETE_MULTIPLE_PARTICIPANTS_FAIL
  | typeof DELETE_PARTICIPANTS_CERTIFICATES_STATUSES_START
  | typeof DELETE_PARTICIPANTS_CERTIFICATES_STATUSES_SUCCESS
  | typeof DELETE_PARTICIPANTS_CERTIFICATES_STATUSES_FAIL
  | typeof SET_SEARCHED_PARTICIPANT
  | typeof CHANGE_PARTICIPANT_LANGUAGE_START
  | typeof CHANGE_PARTICIPANT_LANGUAGE_SUCCESS
  | typeof CHANGE_PARTICIPANT_LANGUAGE_FAIL
  | typeof RESET_DELETED_PARTICIPANTS_CERTIFICATES_STATUSES_STORE
  | typeof RESET_PARTICIPANTS_SUGGESTIONS
  | typeof RESET_GLOBAL_PARTICIPANTS_SUGGESTIONS
  | typeof RESET_UPDATED_PARTICIPANT_WITH_ATTRIBUTES_PARTICIPANT
  | typeof RESET_SEARCHED_PARTICIPANT
  | typeof RESET_DELETED_MULTIPLE_PARTICIPANTS_STORE
  | typeof RESET_PARTICIPANT_STORE
  | typeof RESET_UPDATE_PARTICIPANT_STORE
  | typeof LOGOUT;
