import React, { useEffect, useLayoutEffect, useMemo } from 'react';
import { ReactSVG } from 'react-svg';
import { IntlShape, useIntl } from 'react-intl';
import styles from './PublicSidebar.module.scss';
import ababaTechLogo from '../../../assets/ababa-tech-logo.svg';
import LogoLink from '../LogoLink/LogoLink';
import logo from '../../../assets/procertyLogo_beta.png';
import PublicSidebarItemsGroup from './PublicSidebarItemsGroup/PublicSidebarItemsGroup';
import { PublicNavItem } from '../PublicNavigation';
import { translate } from '../../../utility/messageTranslator/translate';
import { User } from '../../../domain/User';
import { NavLink, useNavigate } from 'react-router-dom';
import { routes } from '../../../config/Router/routes';
import infoCircleIcon from '../../../assets/icons/info_circle.svg';
import PublicSidebarCompanyAndSignatures from './PublicSidebarCompanyAndSignatures/PublicSidebarCompanyAndSignatures';
import { useForm } from '../../../hooks/useForm/useForm';
import TextField from '../../../common/TextField/TextField';
import { Roles } from '../../../domain/Role';
import { ThunkDispatch } from 'redux-thunk';
import { StoreState } from '../../../config/StoreProvider/StoreProvider';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import * as profileService from '../../../store/profile/service';
import { SetSelectedRoleRequest } from '../../../store/profile/service';
import arrowDown from '../../../assets/icons/arrow-down.svg';
import userIcon from '../../../assets/icons/user.svg';
import warningIcon from '../../../assets/icons/warning.svg';
import ProfileWithImage from '../../../common/ProfileWithImage/ProfileWithImage';

type Props = {
  generalItems: PublicNavItem[];
  profileSettingsItems: PublicNavItem[];
  onAddCreditsModalOpen: () => void;
  currentUser: User | null;
  onSetSelectedRole: (inputs: SetSelectedRoleRequest, intl: IntlShape) => void;
  isLoading: boolean;
  selectedCompanyId: number | null;
};

type FormInputs = {
  selectedRole: Roles;
};

const PublicSidebar = ({
  generalItems,
  profileSettingsItems,
  onAddCreditsModalOpen,
  currentUser,
  onSetSelectedRole,
  isLoading,
  selectedCompanyId,
}: Props) => {
  const intl = useIntl();
  const navigate = useNavigate();

  const isOrganiser = useMemo(() => {
    return !!currentUser?.roles.some((role) => role.role === Roles.OWNER);
  }, [currentUser]);

  const isPhoneVerified = useMemo(() => {
    if (!currentUser?.profile) {
      return;
    }

    return currentUser.profile.isPhoneVerified;
  }, [currentUser]);

  const isMissingProfileDetails = useMemo(() => {
    if (!currentUser || !currentUser.profile) {
      return false;
    }

    const profile = currentUser.profile;
    const organiserInfo = currentUser?.organiserInfo;

    if (profile.selectedRole === Roles.OWNER) {
      return (
        !organiserInfo ||
        !organiserInfo.email ||
        !organiserInfo.phone ||
        !organiserInfo.country ||
        !organiserInfo.companyAddress ||
        !organiserInfo.companyCode ||
        !organiserInfo.companyName ||
        !organiserInfo.postalCode
      );
    }

    return !profile.firstName || !profile.lastName;
  }, [currentUser]);

  const showProfileLink = useMemo(() => {
    if (
      !currentUser ||
      !currentUser.profile ||
      currentUser.profile.selectedRole !== Roles.OWNER
    ) {
      return false;
    }

    return !isPhoneVerified || isMissingProfileDetails;
  }, [isPhoneVerified, isMissingProfileDetails, currentUser]);

  const options = useMemo(() => {
    const defaultRoleOptions =
      currentUser?.roles.map((role) => ({
        label: translate(intl, `ROLE_SELECTION.ROLE_${role.role}`),
        value: role.role,
      })) || [];

    const isCompanyValid = currentUser?.companies.some(
      (company) => company.id === selectedCompanyId,
    );

    if (!selectedCompanyId) {
      return defaultRoleOptions;
    }

    if (isCompanyValid) {
      return defaultRoleOptions;
    }

    return (
      currentUser?.userCompanyRoles?.map((companyRole) => ({
        label: translate(intl, `ROLE_OPTION.${companyRole.role}`),
        value: companyRole.role,
      })) || []
    );
  }, [currentUser?.userCompanyRoles, selectedCompanyId]);

  const INPUTS = [
    {
      name: 'selectedRole',
      type: 'autocomplete',
      options,
      value: currentUser?.profile?.selectedRole,
    },
  ];

  const handleFilterChange = ({ selectedRole }: FormInputs) => {
    onSetSelectedRole(
      {
        selectedRole,
      },
      intl,
    );
  };

  const { inputs, onSelectChange, setNewInputObject } = useForm<FormInputs>(
    INPUTS,
    handleFilterChange,
    {
      submitOnChange: true,
    },
  );

  useEffect(() => {
    setNewInputObject('selectedRole', {
      options: options,
      value: options[0]?.value,
    });
  }, [selectedCompanyId]);

  useLayoutEffect(() => {
    if (
      options?.length &&
      currentUser?.profile?.selectedCompanyId !== selectedCompanyId
    ) {
      onSetSelectedRole(
        {
          selectedRole: options[0]?.value,
        },
        intl,
      );
    }
  }, [options, currentUser?.profile?.selectedCompanyId, selectedCompanyId]);

  useEffect(() => {
    setNewInputObject('selectedRole', {
      value: currentUser?.profile?.selectedRole,
    });
  }, [currentUser?.profile]);

  return (
    <div className={styles.publicSidebarContainer}>
      <div className={styles.topSection}>
        <LogoLink logoSrc={logo} logoClassName={styles.logo} isNotSvg />
        <PublicSidebarCompanyAndSignatures
          currentUser={currentUser}
          onAddCreditsModalOpen={onAddCreditsModalOpen}
        />
        <PublicSidebarItemsGroup sidebarItems={generalItems} />
      </div>
      <div className={styles.bottomSection}>
        {!isOrganiser && (
          <div
            className={styles.becomeOrganiser}
            onClick={() => navigate(routes.becomeOrganiser)}
          >
            <ReactSVG src={userIcon} />
            {translate(
              intl,
              'PUBLIC_NAVIGATION.NAVIGATION_ITEM_BECOME_ORGANISER',
            )}
          </div>
        )}
        <PublicSidebarItemsGroup sidebarItems={profileSettingsItems} />
        <div className={styles.roleSelectionContainer}>
          {inputs.map((input) => (
            <TextField
              key={input.name}
              value={input.value?.toString() ?? ''}
              name={input.name}
              type={input.type}
              options={input.options}
              onSelectChange={onSelectChange}
              isLoading={isLoading}
              endContainer={<ReactSVG src={arrowDown} />}
              disableSelectTyping
              popperClassName={styles.popper}
            />
          ))}
        </div>
        {!isMissingProfileDetails && (
          <div
            className={styles.profileContainer}
            onClick={() => navigate(routes.profile)}
          >
            <div className={styles.profileInfo}>
              <ProfileWithImage
                firstName={currentUser?.profile?.firstName}
                lastName={currentUser?.profile?.lastName}
                email={currentUser?.email}
                profileImageSrc={
                  currentUser?.profile?.profileImage?.thumbLocation
                }
                link={
                  currentUser?.profile?.selectedRole === Roles.USER
                    ? routes.participantProfile
                    : routes.profile
                }
              />
            </div>
          </div>
        )}
        {showProfileLink && (
          <div className={styles.missingProfileInfoContainer}>
            {isMissingProfileDetails && (
              <div className={styles.messageContainer}>
                <ReactSVG src={infoCircleIcon} className={styles.icon} />
                {translate(intl, 'PUBLIC_NAVIGATION.MISSING_PROFILE_INFO')}
              </div>
            )}
            {!isPhoneVerified &&
              currentUser?.profile?.selectedRole === Roles.OWNER && (
                <div className={styles.messageContainerInfo}>
                  <ReactSVG src={warningIcon} />
                  {translate(intl, 'PUBLIC_NAVIGATION.PHONE_NOT_VERIFIED')}
                </div>
              )}
            <NavLink
              className={styles.fillProfileInfo}
              to={`${
                currentUser?.profile?.selectedRole === Roles.USER
                  ? routes.participantProfile
                  : routes.profile
              }?tabId=0`}
            >
              {translate(intl, 'PUBLIC_NAVIGATION.FILL_PROFILE_INFO')}
            </NavLink>
          </div>
        )}
        <div className={styles.bottomItems}>
          <a
            href="https://ababa.tech/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={ababaTechLogo} alt="ababa.tech logo" />
          </a>
          <div className={styles.version}>{process.env.REACT_APP_VERSION}</div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: StoreState) => ({
  isLoading: state.profile.setSelectedRoleLoading,
  selectedCompanyId: state.company.selectedCompany,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onSetSelectedRole: (inputs: SetSelectedRoleRequest, intl: IntlShape) =>
    dispatch(profileService.setSelectedRole(inputs, intl)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PublicSidebar);
