export const FETCH_USER_START = 'FETCH_USER_START';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAIL = 'FETCH_USER_FAIL';

export const FETCH_USERS_START = 'FETCH_USERS_START';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAIL = 'FETCH_USERS_FAIL';

export const FETCH_ALL_USERS_START = 'FETCH_ALL_USERS_START';
export const FETCH_ALL_USERS_SUCCESS = 'FETCH_ALL_USERS_SUCCESS';
export const FETCH_ALL_USERS_FAIL = 'FETCH_ALL_USERS_FAIL';

export const FETCH_ADMINS_LIST_START = 'FETCH_ADMINS_LIST_START';
export const FETCH_ADMINS_LIST_SUCCESS = 'FETCH_ADMINS_LIST_SUCCESS';
export const FETCH_ADMINS_LIST_FAIL = 'FETCH_ADMINS_LIST_FAIL';

export const FETCH_USER_WITH_CERTIFICATE_IMAGES_START =
  'FETCH_USER_WITH_CERTIFICATE_IMAGES_START';
export const FETCH_USER_WITH_CERTIFICATE_IMAGES_SUCCESS =
  'FETCH_USER_WITH_CERTIFICATE_IMAGES_SUCCESS';
export const FETCH_USER_WITH_CERTIFICATE_IMAGES_FAIL =
  'FETCH_USER_WITH_CERTIFICATE_IMAGES_FAIL';

export const CREATE_ADMIN_START = 'CREATE_ADMIN_START';
export const CREATE_ADMIN_SUCCESS = 'CREATE_ADMIN_SUCCESS';
export const CREATE_ADMIN_FAIL = 'CREATE_ADMIN_FAIL';

export const UPDATE_USER_START = 'UPDATE_USER_START';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL';

export const UPDATE_ADMIN_START = 'UPDATE_ADMIN_START';
export const UPDATE_ADMIN_SUCCESS = 'UPDATE_ADMIN_SUCCESS';
export const UPDATE_ADMIN_FAIL = 'UPDATE_ADMIN_FAIL';

export const DELETE_USER_START = 'DELETE_USER_START';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAIL = 'DELETE_USER_FAIL';

export const DELETE_USER_CERTIFICATE_IMAGE_START =
  'DELETE_USER_CERTIFICATE_IMAGE_START';
export const DELETE_USER_CERTIFICATE_IMAGE_SUCCESS =
  'DELETE_USER_CERTIFICATE_IMAGE_SUCCESS';
export const DELETE_USER_CERTIFICATE_IMAGE_FAIL =
  'DELETE_USER_CERTIFICATE_IMAGE_FAIL';

export const FETCH_ME_START = 'FETCH_ME_START';
export const FETCH_ME_SUCCESS = 'FETCH_ME_SUCCESS';
export const FETCH_ME_FAIL = 'FETCH_ME_FAIL';

export const CHANGE_USER_PASSWORD_START = 'CHANGE_USER_PASSWORD_START';
export const CHANGE_USER_PASSWORD_SUCCESS = 'CHANGE_USER_PASSWORD_SUCCESS';
export const CHANGE_USER_PASSWORD_FAIL = 'CHANGE_USER_PASSWORD_FAIL';
export const RESET_CHANGE_USER_PASSWORD_STORE =
  'RESET_CHANGE_USER_PASSWORD_STORE';

export const UPDATE_USER_INFO_START = 'UPDATE_USER_INFO_START';
export const UPDATE_USER_INFO_SUCCESS = 'UPDATE_USER_INFO_SUCCESS';
export const UPDATE_USER_INFO_FAIL = 'UPDATE_USER_INFO_FAIL';
export const RESET_USER_INFO_STORE = 'RESET_USER_INFO_STORE';

export const UPDATE_CURRENT_ORGANISER_INFO_START =
  'UPDATE_CURRENT_ORGANISER_INFO_START';
export const UPDATE_CURRENT_ORGANISER_INFO_SUCCESS =
  'UPDATE_CURRENT_ORGANISER_INFO_SUCCESS';
export const UPDATE_CURRENT_ORGANISER_INFO_FAIL =
  'UPDATE_CURRENT_ORGANISER_INFO_FAIL';
export const RESET_CURRENT_ORGANISER_INFO_STORE =
  'RESET_CURRENT_ORGANISER_INFO_STORE';

export const UPDATE_ORGANISER_INFO_START = 'UPDATE_ORGANISER_INFO_START';
export const UPDATE_ORGANISER_INFO_SUCCESS = 'UPDATE_ORGANISER_INFO_SUCCESS';
export const UPDATE_ORGANISER_INFO_FAIL = 'UPDATE_ORGANISER_INFO_FAIL';
export const RESET_ORGANISER_INFO_STORE = 'RESET_ORGANISER_INFO_STORE';

export const UPLOAD_USER_CERTIFICATE_IMAGE_START =
  'UPLOAD_USER_CERTIFICATE_IMAGE_START';
export const UPLOAD_USER_CERTIFICATE_IMAGE_SUCCESS =
  'UPLOAD_USER_CERTIFICATE_IMAGE_SUCCESS';
export const UPLOAD_USER_CERTIFICATE_IMAGE_FAIL =
  'UPLOAD_USER_CERTIFICATE_IMAGE_FAIL';

export const UPLOAD_BACKGROUND_IMAGE_START = 'UPLOAD_BACKGROUND_IMAGE_START';
export const UPLOAD_BACKGROUND_IMAGE_SUCCESS =
  'UPLOAD_BACKGROUND_IMAGE_SUCCESS';
export const UPLOAD_BACKGROUND_IMAGE_FAIL = 'UPLOAD_BACKGROUND_IMAGE_FAIL';
export const RESET_USER_UPLOADED_BACKGROUND_IMAGE =
  'RESET_USER_UPLOADED_BACKGROUND_IMAGE';

export const DELETE_BACKGROUND_IMAGE_START = 'DELETE_BACKGROUND_IMAGE_START';
export const DELETE_BACKGROUND_IMAGE_SUCCESS =
  'DELETE_BACKGROUND_IMAGE_SUCCESS';
export const DELETE_BACKGROUND_IMAGE_FAIL = 'DELETE_BACKGROUND_IMAGE_FAIL';

export const FETCH_USER_WITH_BACKGROUND_IMAGES_START =
  'FETCH_USER_WITH_BACKGROUND_IMAGES_START';
export const FETCH_USER_WITH_BACKGROUND_IMAGES_SUCCESS =
  'FETCH_USER_WITH_BACKGROUND_IMAGES_SUCCESS';
export const FETCH_USER_WITH_BACKGROUND_IMAGES_FAIL =
  'FETCH_USER_WITH_BACKGROUND_IMAGES_FAIL';

export const FETCH_OVERVIEW_INFO_START = 'FETCH_OVERVIEW_INFO_START';
export const FETCH_OVERVIEW_INFO_SUCCESS = 'FETCH_OVERVIEW_INFO_SUCCESS';
export const FETCH_OVERVIEW_INFO_FAIL = 'FETCH_OVERVIEW_INFO_FAIL';

export const UPDATE_USER_BALANCE = 'UPDATE_USER_BALANCE';

export const CONNECT_USER_START = 'CONNECT_USER_START';
export const CONNECT_USER_SUCCESS = 'CONNECT_USER_SUCCESS';
export const CONNECT_USER_FAIL = 'CONNECT_USER_FAIL';

export const FETCH_CONNECTED_USERS_START = 'FETCH_CONNECTED_USERS_START';
export const FETCH_CONNECTED_USERS_SUCCESS = 'FETCH_CONNECTED_USERS_SUCCESS';
export const FETCH_CONNECTED_USERS_FAIL = 'FETCH_CONNECTED_USERS_FAIL';

export const CONFIRM_CONNECT_USER_START = 'CONFIRM_CONNECT_USER_START';
export const CONFIRM_CONNECT_USER_SUCCESS = 'CONFIRM_CONNECT_USER_SUCCESS';
export const CONFIRM_CONNECT_USER_FAIL = 'CONFIRM_CONNECT_USER_FAIL';

export const DELETE_CONNECTED_USER_START = 'DELETE_CONNECTED_USER_START';
export const DELETE_CONNECTED_USER_SUCCESS = 'DELETE_CONNECTED_USER_SUCCESS';
export const DELETE_CONNECTED_USER_FAIL = 'DELETE_CONNECTED_USER_FAIL';

export const RESEND_CONNECTED_USER_INVITATION_START =
  'RESEND_CONNECTED_USER_INVITATION_START';
export const RESEND_CONNECTED_USER_INVITATION_SUCCESS =
  'RESEND_CONNECTED_USER_INVITATION_SUCCESS';
export const RESEND_CONNECTED_USER_INVITATION_FAIL =
  'RESEND_CONNECTED_USER_INVITATION_FAIL';

export const ADD_ORGANISER_ROLE_START = 'ADD_ORGANISER_ROLE_START';
export const ADD_ORGANISER_ROLE_SUCCESS = 'ADD_ORGANISER_ROLE_SUCCESS';
export const ADD_ORGANISER_ROLE_FAIL = 'ADD_ORGANISER_ROLE_FAIL';

export const RESET_USER_STORE = 'RESET_USER_STORE';
export const RESET_CREATED_USER = 'RESET_CREATED_USER';
export const RESET_USER_UPLOADED_CERTIFICATE_IMAGE =
  'RESET_USER_UPLOADED_CERTIFICATE_IMAGE';

export const RESET_UPDATED_USER_INFO_STORE = 'RESET_UPDATED_USER_INFO_STORE';

export const LOGOUT = 'LOGOUT';

export type UserActionTypes =
  | typeof FETCH_USER_START
  | typeof FETCH_USER_SUCCESS
  | typeof FETCH_USER_FAIL
  | typeof FETCH_ALL_USERS_START
  | typeof FETCH_ALL_USERS_SUCCESS
  | typeof FETCH_ALL_USERS_FAIL
  | typeof FETCH_ADMINS_LIST_START
  | typeof FETCH_ADMINS_LIST_SUCCESS
  | typeof FETCH_ADMINS_LIST_FAIL
  | typeof FETCH_USERS_START
  | typeof FETCH_USERS_SUCCESS
  | typeof FETCH_USERS_FAIL
  | typeof FETCH_USER_WITH_CERTIFICATE_IMAGES_START
  | typeof FETCH_USER_WITH_CERTIFICATE_IMAGES_SUCCESS
  | typeof FETCH_USER_WITH_CERTIFICATE_IMAGES_FAIL
  | typeof CREATE_ADMIN_START
  | typeof CREATE_ADMIN_SUCCESS
  | typeof CREATE_ADMIN_FAIL
  | typeof UPLOAD_USER_CERTIFICATE_IMAGE_START
  | typeof UPLOAD_USER_CERTIFICATE_IMAGE_SUCCESS
  | typeof UPLOAD_USER_CERTIFICATE_IMAGE_FAIL
  | typeof UPDATE_USER_START
  | typeof UPDATE_USER_SUCCESS
  | typeof UPDATE_USER_FAIL
  | typeof UPDATE_ADMIN_START
  | typeof UPDATE_ADMIN_SUCCESS
  | typeof UPDATE_ADMIN_FAIL
  | typeof DELETE_USER_START
  | typeof DELETE_USER_SUCCESS
  | typeof DELETE_USER_FAIL
  | typeof DELETE_USER_CERTIFICATE_IMAGE_START
  | typeof DELETE_USER_CERTIFICATE_IMAGE_SUCCESS
  | typeof DELETE_USER_CERTIFICATE_IMAGE_FAIL
  | typeof FETCH_ME_START
  | typeof FETCH_ME_SUCCESS
  | typeof FETCH_ME_FAIL
  | typeof UPDATE_USER_INFO_START
  | typeof UPDATE_USER_INFO_SUCCESS
  | typeof UPDATE_USER_INFO_FAIL
  | typeof UPDATE_CURRENT_ORGANISER_INFO_START
  | typeof UPDATE_CURRENT_ORGANISER_INFO_SUCCESS
  | typeof UPDATE_CURRENT_ORGANISER_INFO_FAIL
  | typeof UPDATE_ORGANISER_INFO_START
  | typeof UPDATE_ORGANISER_INFO_SUCCESS
  | typeof UPDATE_ORGANISER_INFO_FAIL
  | typeof CHANGE_USER_PASSWORD_START
  | typeof CHANGE_USER_PASSWORD_SUCCESS
  | typeof CHANGE_USER_PASSWORD_FAIL
  | typeof UPLOAD_BACKGROUND_IMAGE_START
  | typeof UPLOAD_BACKGROUND_IMAGE_SUCCESS
  | typeof UPLOAD_BACKGROUND_IMAGE_FAIL
  | typeof DELETE_BACKGROUND_IMAGE_START
  | typeof DELETE_BACKGROUND_IMAGE_SUCCESS
  | typeof DELETE_BACKGROUND_IMAGE_FAIL
  | typeof FETCH_USER_WITH_BACKGROUND_IMAGES_START
  | typeof FETCH_USER_WITH_BACKGROUND_IMAGES_SUCCESS
  | typeof FETCH_USER_WITH_BACKGROUND_IMAGES_FAIL
  | typeof FETCH_OVERVIEW_INFO_START
  | typeof FETCH_OVERVIEW_INFO_SUCCESS
  | typeof FETCH_OVERVIEW_INFO_FAIL
  | typeof CONNECT_USER_START
  | typeof CONNECT_USER_SUCCESS
  | typeof CONNECT_USER_FAIL
  | typeof FETCH_CONNECTED_USERS_START
  | typeof FETCH_CONNECTED_USERS_SUCCESS
  | typeof FETCH_CONNECTED_USERS_FAIL
  | typeof CONFIRM_CONNECT_USER_START
  | typeof CONFIRM_CONNECT_USER_SUCCESS
  | typeof CONFIRM_CONNECT_USER_FAIL
  | typeof DELETE_CONNECTED_USER_START
  | typeof DELETE_CONNECTED_USER_SUCCESS
  | typeof DELETE_CONNECTED_USER_FAIL
  | typeof RESEND_CONNECTED_USER_INVITATION_START
  | typeof RESEND_CONNECTED_USER_INVITATION_SUCCESS
  | typeof RESEND_CONNECTED_USER_INVITATION_FAIL
  | typeof ADD_ORGANISER_ROLE_START
  | typeof ADD_ORGANISER_ROLE_SUCCESS
  | typeof ADD_ORGANISER_ROLE_FAIL
  | typeof RESET_USER_UPLOADED_BACKGROUND_IMAGE
  | typeof RESET_CHANGE_USER_PASSWORD_STORE
  | typeof RESET_USER_INFO_STORE
  | typeof RESET_CURRENT_ORGANISER_INFO_STORE
  | typeof RESET_ORGANISER_INFO_STORE
  | typeof RESET_USER_STORE
  | typeof RESET_CREATED_USER
  | typeof RESET_USER_UPLOADED_CERTIFICATE_IMAGE
  | typeof RESET_UPDATED_USER_INFO_STORE
  | typeof LOGOUT;
