import React, { Suspense, useEffect, useMemo } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import Loader from '../../common/Loader/Loader';
import { StoreState } from '../StoreProvider/StoreProvider';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import * as authService from '../../store/auth/service';
import * as userService from '../../store/user/service';
import { routes } from './routes';
import Layout from '../../common/Layout/Layout';
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import { JwtToken } from '../Axios/axios-instance';
import { logout, selectLocale } from '../../store/auth/actions';
import * as languageService from '../../store/language/service';
import { Language } from '../../domain/Language';
import { DEFAULT_LOCALE } from '../constants';
import { Locale } from '../../domain/Translation';
import { IntlProvider } from 'react-intl';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from '../../common/ErrorFallback/ErrorFallback';
import { User } from '../../domain/User';
import { SignUpSectionType } from '../../pages/Public/SignUpPage/SignUpPage';
import { Roles } from '../../domain/Role';
import { setSelectedCompany } from 'src/store/company/actions';

const SignUpPage = React.lazy(
  () => import('../../pages/Public/SignUpPage/SignUpPage'),
);

const RegistrationConfirmationPage = React.lazy(
  () =>
    import(
      '../../pages/Public/RegistrationConfirmationPage/RegistrationConfirmationPage'
    ),
);

const PasswordResetPage = React.lazy(
  () => import('../../pages/Public/PasswordResetPage/PasswordResetPage'),
);

const TemplatesListPage = React.lazy(
  () =>
    import('../../pages/Admin/Template/TemplatesListPage/TemplatesListPage'),
);

const UsersListPage = React.lazy(
  () => import('../../pages/Admin/User/UsersListPage/UsersListPage'),
);

const OrganizersListPage = React.lazy(
  () => import('../../pages/Admin/User/OrganizersListPage/OrganizersListPage'),
);

const OrganizerProfilePage = React.lazy(
  () =>
    import('../../pages/Admin/User/OrganizerProfilePage/OrganizerProfilePage'),
);

const UserCreatePage = React.lazy(
  () => import('../../pages/Admin/User/UserCreatePage/UserCreatePage'),
);

const UserEditPage = React.lazy(
  () => import('../../pages/Admin/User/UserEditPage/UserEditPage'),
);

const TranslationsPage = React.lazy(
  () =>
    import(
      '../../pages/Admin/Translation/TranslationListPage/TranslationListPage'
    ),
);

const PaymentsPage = React.lazy(
  () => import('../../pages/Admin/Payment/PaymentListPage/PaymentListPage'),
);

const DynamicPage = React.lazy(
  () => import('../../pages/Public/DynamicPage/DynamicPage'),
);

const ComingSoonPage = React.lazy(
  () => import('../../pages/Public/ComingSoonPage/ComingSoonPage'),
);

const LanguagesListPage = React.lazy(
  () => import('../../pages/Admin/Language/LanguagesListPage'),
);

const TermsAndConditionsPage = React.lazy(
  () =>
    import('../../pages/Public/TermsAndConditionsPage/TermsAndConditionsPage'),
);

const ProfilePage = React.lazy(
  () => import('../../pages/Public/ProfilePage/ProfilePage'),
);

const DashboardPage = React.lazy(
  () => import('../../pages/Public/DashboardPage/DashboardPage'),
);

const CertificateListPage = React.lazy(
  () =>
    import(
      '../../pages/Public/Certificate/CertificateTemplateListPage/CertificateTemplateListPage'
    ),
);

const CertificateFormPage = React.lazy(
  () =>
    import(
      '../../pages/Public/Certificate/CertificateFormPage/CertificateFormPage'
    ),
);

const CertificateParticipantListPage = React.lazy(
  () =>
    import(
      '../../pages/Public/Certificate/CertificateParticipantListPage/CertificateParticipantListPage'
    ),
);

const AdminsListPage = React.lazy(
  () => import('../../pages/Admin/AdminsListPage/AdminsListPage'),
);

const SignRedirect = React.lazy(
  () => import('../../pages/Public/SignRedirect/SignRedirect'),
);

const ParticipantListPage = React.lazy(
  () =>
    import(
      '../../pages/Public/Participant/ParticipantListPage/ParticipantListPage'
    ),
);

const ParticipantDashboardPage = React.lazy(
  () =>
    import(
      '../../pages/Public/ParticipantDashboardPage/ParticipantDashboardPage'
    ),
);

const ParticipantProfilePage = React.lazy(
  () =>
    import('../../pages/Public/ParticipantProfilePage/ParticipantProfilePage'),
);

const CommunicationListPage = React.lazy(
  () =>
    import(
      '../../pages/Public/Communication/CommunicationListPage/CommunicationListPage'
    ),
);

const ConfirmConnection = React.lazy(
  () =>
    import(
      '../../pages/Public/ParticipantProfilePage/ConfirmConnection/ConfirmConnection'
    ),
);

const SharedCertificatePage = React.lazy(
  () =>
    import('../../pages/Public/SharedCertificatePage/SharedCertificatePage'),
);

const SharedParticipantCertificateSectionPage = React.lazy(
  () =>
    import(
      '../../pages/Public/SharedParticipantCertificateSectionPage/SharedParticipantCertificateSectionPage'
    ),
);

const CreditPackagesListPage = React.lazy(
  () =>
    import(
      '../../pages/Admin/CreditPackage/CreditPackagesListPage/CreditPackagesListPage'
    ),
);

const BecomeOrganiserForm = React.lazy(
  () =>
    import(
      '../../pages/Public/BecomeOrganiserFormPage/BecomeOrganiserFormPage'
    ),
);

const PrivacyPolicyPage = React.lazy(
  () => import('../../pages/Public/PrivacyPolicyPage/PrivacyPolicyPage'),
);

export type Props = {
  isInitCompleted: boolean;
  isAuthenticated: boolean;
  onTryAutoSignup: () => void;
  isCurrentUserLoading: boolean;
  refreshTokenLoading: boolean;
  onFetchCurrentUser: () => void;
  onRefreshToken: () => void;
  jwtToken: string | null;
  lastActionAt: moment.Moment | null;
  onLogout: () => void;
  onLanguageFetch: (locale: string) => void;
  language: Language | null;
  onSelectLocale: (locale: Locale) => void;
  selectedLocale: Locale;
  currentUser: User | null;
  onLanguagesInit: () => void;
  isCurrentUserRefreshNeeded: boolean;
  onCompanySelect: (selectCompanyId: number | null) => void;
};

export const Router = ({
  isInitCompleted,
  isAuthenticated,
  onTryAutoSignup,
  isCurrentUserLoading,
  onFetchCurrentUser,
  refreshTokenLoading,
  onRefreshToken,
  jwtToken,
  lastActionAt,
  onLogout,
  onLanguageFetch,
  language,
  selectedLocale,
  onSelectLocale,
  currentUser,
  onLanguagesInit,
  isCurrentUserRefreshNeeded,
  onCompanySelect,
}: Props) => {
  useEffect(() => {
    if (!jwtToken) {
      return;
    }

    const decodedJson: JwtToken = jwtDecode(jwtToken);

    if (!decodedJson) {
      return;
    }

    const difference = moment.duration(
      moment(decodedJson.exp * 1000).diff(moment()),
    );
    const differenceLastAction = moment.duration(moment().diff(lastActionAt));

    if (
      difference.asMinutes() < 60 &&
      differenceLastAction.asMinutes() < 60 &&
      !refreshTokenLoading
    ) {
      onRefreshToken();
    }

    const timeout = setTimeout(() => {
      onLogout();
    }, difference.asMilliseconds());

    return () => clearTimeout(timeout);
  }, [jwtToken, lastActionAt]);

  useEffect(() => {
    onTryAutoSignup();
  }, []);

  useEffect(() => {
    onFetchCurrentUser();
  }, [isCurrentUserRefreshNeeded]);

  useEffect(() => {
    if (isAuthenticated) {
      onFetchCurrentUser();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    moment.locale(DEFAULT_LOCALE);
    onLanguageFetch(DEFAULT_LOCALE);
    onLanguagesInit();
    onSelectLocale(DEFAULT_LOCALE);
  }, []);

  useEffect(() => {
    if (currentUser?.profile?.selectedCompanyId) {
      onCompanySelect(currentUser.profile.selectedCompanyId);
    }
  }, [currentUser?.profile?.selectedCompanyId]);

  const mappedTranslations = language?.translations?.reduce(
    (obj, item) =>
      Object.assign(obj, {
        [item.alias]: item.value ? item.value : item.defaultValue,
      }),
    {},
  );

  const redirectRoute = useMemo(() => {
    if (currentUser?.profile?.selectedRole === Roles.USER) {
      return routes.participantDashboard;
    }

    if (currentUser?.profile?.selectedRole === Roles.DESIGNER) {
      return routes.certificate.list;
    }

    return routes.dashboard;
  }, [currentUser?.profile?.selectedRole]);

  const getRoutes = () => {
    if (!isAuthenticated) {
      return (
        <>
          <Route
            path={routes.admin}
            element={<Navigate replace to={routes.login} />}
          />
          <Route path={routes.login} element={<SignUpPage />} />
          <Route
            path={routes.register}
            element={<SignUpPage sectionType={SignUpSectionType.REGISTER} />}
          />
          <Route
            path={routes.registrationConfirmation}
            element={<RegistrationConfirmationPage />}
          />
          <Route path={routes.resetPassword} element={<PasswordResetPage />} />
          <Route path={routes.comingSoon} element={<ComingSoonPage />} />
          <Route
            path={routes.termsAndConditions}
            element={<TermsAndConditionsPage />}
          />
          <Route
            path={routes.confirmConnection}
            element={<ConfirmConnection />}
          />
          <Route
            path={routes.certificate.shared}
            element={<SharedCertificatePage />}
          />
          <Route
            path={routes.section.shared}
            element={<SharedParticipantCertificateSectionPage />}
          />
          <Route path="*" element={<Navigate to={routes.login} />} />
        </>
      );
    }

    const getAdminRoutes = () => {
      if (currentUser?.profile?.selectedRole !== Roles.ADMIN) {
        return <></>;
      }

      return (
        <>
          <Route
            path={routes.admin}
            element={<Navigate replace to={routes.users.list} />}
          />
          <Route path={routes.templates.list} element={<TemplatesListPage />} />
          <Route
            path={routes.templates.form}
            element={<CertificateFormPage isTemplateForm={true} />}
          />
          <Route path={routes.users.create} element={<UserCreatePage />} />
          <Route path={routes.users.edit} element={<UserEditPage />} />
          <Route path={routes.users.list} element={<UsersListPage />} />
          <Route
            path={routes.organizers.edit}
            element={<OrganizerProfilePage />}
          />
          <Route
            path={routes.organizers.list}
            element={<OrganizersListPage />}
          />
          <Route path={routes.admins.list} element={<AdminsListPage />} />
          <Route
            path={routes.translations.list}
            element={<TranslationsPage />}
          />
          <Route path={routes.payments.list} element={<PaymentsPage />} />
          <Route path={routes.languages} element={<LanguagesListPage />} />
          <Route
            path={routes.creditPackages.list}
            element={<CreditPackagesListPage />}
          />
        </>
      );
    };

    const getOwnerRoutes = () => {
      if (
        currentUser?.profile?.selectedRole !== Roles.OWNER &&
        currentUser?.profile?.selectedRole !== Roles.ADMIN &&
        currentUser?.profile?.selectedRole !== Roles.MANAGER
      ) {
        return <></>;
      }

      return (
        <>
          <Route path={routes.profile} element={<ProfilePage />} />
          <Route path={routes.dashboard} element={<DashboardPage />} />
          <Route path={routes.dynamicPage} element={<DynamicPage />} />
          <Route
            path={routes.certificate.list}
            element={<CertificateListPage />}
          />
          <Route
            path={routes.communication.list}
            element={<CommunicationListPage />}
          />
          <Route
            path={routes.certificate.form}
            element={<CertificateFormPage />}
          />
          <Route
            path={routes.certificate.participants}
            element={<CertificateParticipantListPage />}
          />
          <Route
            path={routes.participants.list}
            element={<ParticipantListPage />}
          />
          <Route
            path={routes.certificate.shared}
            element={<SharedCertificatePage />}
          />
          <Route
            path={routes.section.shared}
            element={<SharedParticipantCertificateSectionPage />}
          />
          <Route path={routes.signRedirect} element={<SignRedirect />} />
        </>
      );
    };

    const getDesignerRoutes = () => {
      if (currentUser?.profile?.selectedRole !== Roles.DESIGNER) {
        return <></>;
      }

      return (
        <>
          <Route path={routes.profile} element={<ProfilePage />} />
          <Route
            path={routes.certificate.list}
            element={<CertificateListPage />}
          />
          <Route
            path={routes.certificate.form}
            element={<CertificateFormPage />}
          />
          <Route path={routes.signRedirect} element={<SignRedirect />} />
        </>
      );
    };

    const getUserRoutes = () => {
      if (currentUser?.profile?.selectedRole !== Roles.USER) {
        return <></>;
      }

      return (
        <>
          <Route
            path={routes.participantDashboard}
            element={<ParticipantDashboardPage />}
          />
          <Route
            path={routes.participantProfile}
            element={<ParticipantProfilePage />}
          />
          <Route
            path={routes.confirmConnection}
            element={<ConfirmConnection />}
          />
          <Route
            path={routes.certificate.shared}
            element={<SharedCertificatePage />}
          />
          <Route
            path={routes.section.shared}
            element={<SharedParticipantCertificateSectionPage />}
          />
          <Route
            path={routes.termsAndConditions}
            element={<TermsAndConditionsPage />}
          />
          <Route path={routes.privacyPolicy} element={<PrivacyPolicyPage />} />
        </>
      );
    };

    const getOnlyUserRoutes = () => {
      return (
        <>
          <Route
            path={routes.becomeOrganiser}
            element={<BecomeOrganiserForm />}
          />
        </>
      );
    };

    return (
      <>
        {getAdminRoutes()}
        {getOwnerRoutes()}
        {getUserRoutes()}
        {getOnlyUserRoutes()}
        {getDesignerRoutes()}
        <Route
          path={routes.termsAndConditions}
          element={<TermsAndConditionsPage />}
        />
        <Route path={routes.privacyPolicy} element={<PrivacyPolicyPage />} />

        <Route path="*" element={<Navigate to={redirectRoute} />} />
      </>
    );
  };

  return (
    <BrowserRouter basename="/">
      {isInitCompleted && !isCurrentUserLoading && language ? (
        <IntlProvider
          messages={mappedTranslations}
          locale={language?.locale ?? DEFAULT_LOCALE}
          defaultLocale="en"
        >
          <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onReset={() => {
              window.location.reload();
            }}
          >
            <Suspense fallback={<Loader isLoading isFullScreen />}>
              <Layout>
                <Routes>{getRoutes()}</Routes>
              </Layout>
            </Suspense>
          </ErrorBoundary>
        </IntlProvider>
      ) : (
        <Loader isLoading isFullScreen />
      )}
    </BrowserRouter>
  );
};

const mapStateToProps = (state: StoreState) => ({
  isInitCompleted: state.auth.isInitCompleted,
  isAuthenticated: state.auth.isAuthenticated,
  isCurrentUserLoading: state.user.currentUserLoading,
  refreshTokenLoading: state.auth.refreshTokenLoading,
  jwtToken: state.auth.jwtToken,
  lastActionAt: state.auth.lastStoreActionAt,
  language: state.language.language,
  selectedLocale: state.auth.selectedLocale,
  currentUser: state.user.currentUser,
  isCurrentUserRefreshNeeded: state.profile.setSelectedRoleSuccess,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onTryAutoSignup: () => dispatch(authService.authCheckState()),
  onFetchCurrentUser: () => dispatch(userService.fetchCurrentUser()),
  onRefreshToken: () => dispatch(authService.refreshToken()),
  onLanguageFetch: (locale: string) =>
    dispatch(languageService.fetchLanguage(locale)),
  onLogout: () => dispatch(logout()),
  onSelectLocale: (locale: Locale) => dispatch(selectLocale(locale)),
  onLanguagesInit: () => dispatch(languageService.fetchLanguages()),
  onCompanySelect: (selectCompanyId: number | null) =>
    dispatch(setSelectedCompany(selectCompanyId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Router);
