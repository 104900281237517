import * as actionTypes from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { UserCompanyRole } from '../../domain/UserCompanyRole';

export const selectCompanyStart = () => ({
  type: actionTypes.SELECT_COMPANY_START,
});

export const selectCompanySuccess = (selectedCompany: number) => ({
  type: actionTypes.SELECT_COMPANY_SUCCESS,
  selectedCompany,
});

export const selectCompanyFail = (selectCompanyError: HttpError) => ({
  type: actionTypes.SELECT_COMPANY_FAIL,
  selectCompanyError,
});

export const inviteToCompanyStart = () => ({
  type: actionTypes.INVITE_TO_COMPANY_START,
});

export const inviteToCompanySuccess = () => ({
  type: actionTypes.INVITE_TO_COMPANY_SUCCESS,
});

export const inviteToCompanyFail = (inviteToCompanyError: HttpError) => ({
  type: actionTypes.INVITE_TO_COMPANY_FAIL,
  inviteToCompanyError,
});

export const setSelectedCompany = (selectedCompany: number | null) => {
  selectedCompany &&
    localStorage.setItem('selectedCompany', selectedCompany.toString());

  return {
    type: actionTypes.SET_SELECTED_COMPANY,
    selectedCompany,
  };
};

export const fetchAllCompanyUsersStart = () => ({
  type: actionTypes.FETCH_ALL_COMPANY_USERS_START,
});

export const fetchAllCompanyUsersSuccess = (
  allCompanyUsers: UserCompanyRole[],
) => ({
  type: actionTypes.FETCH_ALL_COMPANY_USERS_SUCCESS,
  allCompanyUsers,
});

export const fetchAllCompanyUsersFail = (allCompanyUsersError: HttpError) => ({
  type: actionTypes.FETCH_ALL_COMPANY_USERS_FAIL,
  allCompanyUsersError,
});

export const removeUserFromCompanyStart = () => ({
  type: actionTypes.REMOVE_USER_FROM_COMPANY_START,
});

export const removeUserFromCompanySuccess = () => ({
  type: actionTypes.REMOVE_USER_FROM_COMPANY_SUCCESS,
});

export const removeUserFromCompanyFail = (
  removeUserFromCompanyError: HttpError,
) => ({
  type: actionTypes.REMOVE_USER_FROM_COMPANY_FAIL,
  removeUserFromCompanyError,
});
