export const FETCH_COMMUNICATION_LIST_START = 'FETCH_COMMUNICATION_LIST_START';
export const FETCH_COMMUNICATION_LIST_SUCCESS =
  'FETCH_COMMUNICATION_LIST_SUCCESS';
export const FETCH_COMMUNICATION_LIST_FAIL = 'FETCH_COMMUNICATIONS_FAIL';

export const CREATE_COMMUNICATION_START = 'CREATE_COMMUNICATION_START';
export const CREATE_COMMUNICATION_SUCCESS = 'CREATE_COMMUNICATION_SUCCESS';
export const CREATE_COMMUNICATION_FAIL = 'CREATE_COMMUNICATION_FAIL';

export const RESET_CREATED_COMMUNICATION_STORE =
  'RESET_CREATED_COMMUNICATION_STORE';
export const RESET_COMMUNICATION_STORE = 'RESET_COMMUNICATION_STORE';

export const LOGOUT = 'LOGOUT';

export type CommunicationActionTypes =
  | typeof FETCH_COMMUNICATION_LIST_START
  | typeof FETCH_COMMUNICATION_LIST_SUCCESS
  | typeof FETCH_COMMUNICATION_LIST_FAIL
  | typeof CREATE_COMMUNICATION_START
  | typeof CREATE_COMMUNICATION_SUCCESS
  | typeof CREATE_COMMUNICATION_FAIL
  | typeof RESET_CREATED_COMMUNICATION_STORE
  | typeof RESET_COMMUNICATION_STORE
  | typeof LOGOUT;
