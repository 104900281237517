import { Company } from './Company';
import { CreditPackage } from './CreditPackage';
import { Invoice } from './Invoice';
import { User } from './User';

export interface Payment {
  id: number;
  amount: number;
  invoice: Invoice;
  subscriptionType: SubscriptionType;
  isPaid: boolean;
  user: User;
  createdAt: string;
  creditPackage?: CreditPackage;
  company: Company;
}

export enum SubscriptionType {
  // eslint-disable-next-line no-unused-vars
  BASE = 'BASE',
  // eslint-disable-next-line no-unused-vars
  OPTIMAL = 'OPTIMAL',
  // eslint-disable-next-line no-unused-vars
  EXPERIENCED = 'EXPERIENCED',
  // eslint-disable-next-line no-unused-vars
  PROFESSIONAL = 'PROFESSIONAL',
}

export enum PaymentStatusTypes {
  // eslint-disable-next-line no-unused-vars
  PAID = 'PAID',
  // eslint-disable-next-line no-unused-vars
  NOT_PAID = 'NOT_PAID',
}

export enum PaymentMethod {
  // eslint-disable-next-line no-unused-vars
  STRIPE = 'STRIPE',
  // eslint-disable-next-line no-unused-vars
  NEOPAY = 'NEOPAY',
}
