export const routes = {
  dashboard: '/dashboard',
  help: 'https://procerty.com/pagalba',
  certificate: {
    list: '/certificates',
    form: '/certificate/form/:id?',
    participants: '/certificates/:id/participants',
    shared: '/shared/certificate/:id',
  },
  privacyPolicy: '/privacy-policy',
  login: '/login',
  register: '/register',
  registrationConfirmation: '/registration-confirmation/:code',
  remind: '/remind-password',
  resetPassword: '/password-reset/:code',
  profile: '/profile',
  participantProfile: '/participant-profile',
  dynamicPage: '/:locale/:slug',
  admin: '/admin',
  users: {
    list: '/admin/users',
    create: '/admin/users/new',
    edit: '/admin/users/:id',
  },
  templates: {
    list: '/admin/templates',
    form: '/admin/template/form/:id?',
  },
  translations: {
    list: '/admin/translations',
  },
  payments: {
    list: '/admin/payments',
  },
  organizers: {
    list: '/admin/organizers',
    edit: '/admin/organizers/:id',
  },
  comingSoon: '/coming-soon',
  languages: '/admin/languages',
  termsAndConditions: '/terms-and-conditions',
  admins: {
    list: '/admins',
  },
  signRedirect: '/sign-redirect-handle',
  participants: {
    list: '/participants/list',
  },
  communication: {
    list: '/communication/list',
  },
  participantDashboard: '/participant-dashboard',
  confirmConnection: '/confirm-connection/:token',
  creditPackages: {
    list: '/admin/credit-packages',
  },
  becomeOrganiser: '/become-organiser',
  section: {
    shared: '/shared/section/:id',
  },
};
