import axios from '../../config/Axios/axios-instance';
import { Dispatch } from 'redux';
import {
  selectCompanyStart,
  selectCompanySuccess,
  selectCompanyFail,
  inviteToCompanyStart,
  inviteToCompanySuccess,
  inviteToCompanyFail,
  fetchAllCompanyUsersStart,
  fetchAllCompanyUsersSuccess,
  fetchAllCompanyUsersFail,
  removeUserFromCompanyStart,
  removeUserFromCompanySuccess,
  removeUserFromCompanyFail,
} from './actions';
import { Roles } from '../../domain/Role';
import { IntlShape } from 'react-intl';
import { showToast } from '../../utility/toast/toast';
import { translate } from '../../utility/messageTranslator/translate';

const API_URL = '/companies';

export type InviteToCompanyRequest = {
  email: string;
  role: Roles;
};

export type RemoveFromCompanyRequest = {
  role: Roles;
};

export const selectCompany =
  (selectedCompanyId: number | null) => (dispatch: Dispatch) => {
    dispatch(selectCompanyStart());
    return axios
      .patch(`profile/select-company`, { id: selectedCompanyId })
      .then((response) => {
        dispatch(selectCompanySuccess(response.data.selectedCompanyId));
      })
      .catch((err) => {
        dispatch(selectCompanyFail(err.response.data.message));
      });
  };

export const inviteToCompany =
  (inputs: InviteToCompanyRequest, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(inviteToCompanyStart());
    return axios
      .post(API_URL, { ...inputs })
      .then(() => {
        dispatch(inviteToCompanySuccess());
        showToast(
          translate(intl, 'SUCCESS.USER_INVITED_TO_COMPANY'),
          'success',
        );
      })
      .catch((err) => {
        dispatch(inviteToCompanyFail(err.response.data.message));
        showToast(translate(intl, err.response.data.message), 'error');
      });
  };

export const fetchAllCompanyUsers = () => (dispatch: Dispatch) => {
  dispatch(fetchAllCompanyUsersStart());
  return axios
    .get(API_URL)
    .then((response) => {
      dispatch(fetchAllCompanyUsersSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchAllCompanyUsersFail(err?.response?.data?.error));
    });
};

export const removeUserFromCompany =
  (id: number, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(removeUserFromCompanyStart());
    return axios
      .delete(`${API_URL}/${id}`)
      .then(() => {
        dispatch(removeUserFromCompanySuccess());
        showToast(
          translate(intl, 'SUCCESS.USER_REMOVED_FROM_COMPANY'),
          'success',
        );
      })
      .catch((err) => {
        dispatch(removeUserFromCompanyFail(err?.response?.data?.message));
      });
  };
