import * as actionTypes from './actionTypes';
import { CompanyInvoiceInfoActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import {
  CompanyInvoiceInfo,
  CompanyInvoiceInfoDetails,
} from '../../domain/CompanyInvoiceInfo';

export type CompanyInvoiceInfoStateType = {
  companyInvoiceInfo: CompanyInvoiceInfo | null;
  companyInvoiceInfoError: HttpError;
  companyInvoiceInfoLoading: boolean;
  currentCompanyInvoiceInfo: CompanyInvoiceInfo | null;
  currentCompanyInvoiceInfoError: HttpError;
  currentCompanyInvoiceInfoLoading: boolean;
  companyInvoiceInfoDetailsLoading: boolean;
  companyInvoiceInfoDetails: CompanyInvoiceInfoDetails | null;
  companyInvoiceInfoDetailsError: HttpError;
  companyInvoiceInfoUpdateLoading: boolean;
  companyInvoiceInfoUpdateError: HttpError;
  companyInvoiceInfoUpdateSuccess: boolean;
  currentCompanyInvoiceInfoUpdateLoading: boolean;
  currentCompanyInvoiceInfoUpdateError: HttpError;
  currentCompanyInvoiceInfoUpdateSuccess: boolean;
};

export type CompanyInvoiceInfoActionType = CompanyInvoiceInfoStateType & {
  type: CompanyInvoiceInfoActionTypes;
  refreshCurrentCompanyInvoiceInfo: boolean;
};

export const initialState: CompanyInvoiceInfoStateType = {
  companyInvoiceInfo: null,
  companyInvoiceInfoError: null,
  companyInvoiceInfoLoading: false,
  currentCompanyInvoiceInfo: null,
  currentCompanyInvoiceInfoError: null,
  currentCompanyInvoiceInfoLoading: false,
  companyInvoiceInfoDetailsLoading: false,
  companyInvoiceInfoDetails: null,
  companyInvoiceInfoDetailsError: null,
  companyInvoiceInfoUpdateError: null,
  companyInvoiceInfoUpdateLoading: false,
  companyInvoiceInfoUpdateSuccess: false,
  currentCompanyInvoiceInfoUpdateError: null,
  currentCompanyInvoiceInfoUpdateLoading: false,
  currentCompanyInvoiceInfoUpdateSuccess: false,
};

const fetchCompanyInvoiceInfoStart = (
  state: CompanyInvoiceInfoStateType,
): CompanyInvoiceInfoStateType => ({
  ...state,
  companyInvoiceInfoLoading: true,
});

const fetchCompanyInvoiceInfoSuccess = (
  state: CompanyInvoiceInfoStateType,
  action: CompanyInvoiceInfoActionType,
): CompanyInvoiceInfoStateType => ({
  ...state,
  companyInvoiceInfo: action.companyInvoiceInfo,
  companyInvoiceInfoLoading: false,
});

const fetchCompanyInvoiceInfoFail = (
  state: CompanyInvoiceInfoStateType,
  action: CompanyInvoiceInfoActionType,
): CompanyInvoiceInfoStateType => ({
  ...state,
  companyInvoiceInfoError: action.companyInvoiceInfoError,
  companyInvoiceInfoLoading: false,
});

const fetchCurrentCompanyInvoiceInfoStart = (
  state: CompanyInvoiceInfoStateType,
): CompanyInvoiceInfoStateType => ({
  ...state,
  currentCompanyInvoiceInfoLoading: true,
});

const fetchCurrentCompanyInvoiceInfoSuccess = (
  state: CompanyInvoiceInfoStateType,
  action: CompanyInvoiceInfoActionType,
): CompanyInvoiceInfoStateType => ({
  ...state,
  currentCompanyInvoiceInfo: action.currentCompanyInvoiceInfo,
  currentCompanyInvoiceInfoLoading: false,
});

const fetchCurrentCompanyInvoiceInfoFail = (
  state: CompanyInvoiceInfoStateType,
  action: CompanyInvoiceInfoActionType,
): CompanyInvoiceInfoStateType => ({
  ...state,
  currentCompanyInvoiceInfoError: action.currentCompanyInvoiceInfoError,
  currentCompanyInvoiceInfoLoading: false,
});

const fetchCompanyInvoiceInfoDetailsStart = (
  state: CompanyInvoiceInfoStateType,
): CompanyInvoiceInfoStateType => ({
  ...state,
  companyInvoiceInfoDetailsLoading: true,
});

const fetchCompanyInvoiceInfoDetailsSuccess = (
  state: CompanyInvoiceInfoStateType,
  action: CompanyInvoiceInfoActionType,
): CompanyInvoiceInfoStateType => ({
  ...state,
  companyInvoiceInfoDetails: action.companyInvoiceInfoDetails,
  companyInvoiceInfoDetailsLoading: false,
});

const fetchCompanyInvoiceInfoDetailsFail = (
  state: CompanyInvoiceInfoStateType,
  action: CompanyInvoiceInfoActionType,
): CompanyInvoiceInfoStateType => ({
  ...state,
  companyInvoiceInfoDetailsError: action.companyInvoiceInfoDetailsError,
  companyInvoiceInfoDetailsLoading: false,
});

const updateCompanyInvoiceInfoStart = (
  state: CompanyInvoiceInfoStateType,
): CompanyInvoiceInfoStateType => ({
  ...state,
  companyInvoiceInfoUpdateLoading: true,
  companyInvoiceInfoUpdateSuccess: false,
});

const updateCompanyInvoiceInfoSuccess = (
  state: CompanyInvoiceInfoStateType,
): CompanyInvoiceInfoStateType => ({
  ...state,
  companyInvoiceInfoUpdateLoading: false,
  companyInvoiceInfoUpdateError: null,
  companyInvoiceInfoUpdateSuccess: true,
});

const updateCompanyInvoiceInfoFail = (
  state: CompanyInvoiceInfoStateType,
  action: CompanyInvoiceInfoActionType,
): CompanyInvoiceInfoStateType => ({
  ...state,
  companyInvoiceInfoUpdateLoading: false,
  companyInvoiceInfoUpdateError: action.companyInvoiceInfoUpdateError,
});

const updateCurrentCompanyInvoiceInfoStart = (
  state: CompanyInvoiceInfoStateType,
): CompanyInvoiceInfoStateType => ({
  ...state,
  currentCompanyInvoiceInfoUpdateLoading: true,
  currentCompanyInvoiceInfoUpdateSuccess: false,
});

const updateCurrentCompanyInvoiceInfoSuccess = (
  state: CompanyInvoiceInfoStateType,
): CompanyInvoiceInfoStateType => ({
  ...state,
  currentCompanyInvoiceInfoUpdateLoading: false,
  currentCompanyInvoiceInfoUpdateError: null,
  currentCompanyInvoiceInfoUpdateSuccess: true,
});

const updateCurrentCompanyInvoiceInfoFail = (
  state: CompanyInvoiceInfoStateType,
  action: CompanyInvoiceInfoActionType,
): CompanyInvoiceInfoStateType => ({
  ...state,
  currentCompanyInvoiceInfoUpdateLoading: false,
  currentCompanyInvoiceInfoUpdateError:
    action.currentCompanyInvoiceInfoUpdateError,
});

const resetCompanyInvoiceInfoStore = (
  state: CompanyInvoiceInfoStateType,
): CompanyInvoiceInfoStateType => ({
  ...initialState,
});

const resetUpdateCompanyInvoiceInfoStore = (
  state: CompanyInvoiceInfoStateType,
): CompanyInvoiceInfoStateType => ({
  ...initialState,
  currentCompanyInvoiceInfoUpdateLoading: false,
  currentCompanyInvoiceInfoUpdateError: null,
  currentCompanyInvoiceInfoUpdateSuccess: false,
});

const completelyResetCompanyInvoiceInfoStore =
  (): CompanyInvoiceInfoStateType => ({
    ...initialState,
  });

const reducer = (
  state = initialState,
  action: CompanyInvoiceInfoActionType,
) => {
  switch (action.type) {
    case actionTypes.FETCH_COMPANY_INVOICE_INFO_START:
      return fetchCompanyInvoiceInfoStart(state);
    case actionTypes.FETCH_COMPANY_INVOICE_INFO_SUCCESS:
      return fetchCompanyInvoiceInfoSuccess(state, action);
    case actionTypes.FETCH_COMPANY_INVOICE_INFO_FAIL:
      return fetchCompanyInvoiceInfoFail(state, action);
    case actionTypes.FETCH_CURRENT_COMPANY_INVOICE_INFO_START:
      return fetchCurrentCompanyInvoiceInfoStart(state);
    case actionTypes.FETCH_CURRENT_COMPANY_INVOICE_INFO_SUCCESS:
      return fetchCurrentCompanyInvoiceInfoSuccess(state, action);
    case actionTypes.FETCH_CURRENT_COMPANY_INVOICE_INFO_FAIL:
      return fetchCurrentCompanyInvoiceInfoFail(state, action);
    case actionTypes.FETCH_COMPANY_INVOICE_INFO_DETAILS_START:
      return fetchCompanyInvoiceInfoDetailsStart(state);
    case actionTypes.FETCH_COMPANY_INVOICE_INFO_DETAILS_SUCCESS:
      return fetchCompanyInvoiceInfoDetailsSuccess(state, action);
    case actionTypes.FETCH_COMPANY_INVOICE_INFO_DETAILS_FAIL:
      return fetchCompanyInvoiceInfoDetailsFail(state, action);
    case actionTypes.UPDATE_COMPANY_INVOICE_INFO_START:
      return updateCompanyInvoiceInfoStart(state);
    case actionTypes.UPDATE_COMPANY_INVOICE_INFO_SUCCESS:
      return updateCompanyInvoiceInfoSuccess(state);
    case actionTypes.UPDATE_COMPANY_INVOICE_INFO_FAIL:
      return updateCompanyInvoiceInfoFail(state, action);
    case actionTypes.UPDATE_CURRENT_COMPANY_INVOICE_INFO_START:
      return updateCurrentCompanyInvoiceInfoStart(state);
    case actionTypes.UPDATE_CURRENT_COMPANY_INVOICE_INFO_SUCCESS:
      return updateCurrentCompanyInvoiceInfoSuccess(state);
    case actionTypes.UPDATE_CURRENT_COMPANY_INVOICE_INFO_FAIL:
      return updateCurrentCompanyInvoiceInfoFail(state, action);
    case actionTypes.RESET_COMPANY_INVOICE_INFO_STORE:
      return resetCompanyInvoiceInfoStore(state);
    case actionTypes.RESET_UPDATE_COMPANY_INVOICE_INFO_STORE:
      return resetUpdateCompanyInvoiceInfoStore(state);
    case actionTypes.LOGOUT:
      return completelyResetCompanyInvoiceInfoStore();
    default:
      return state;
  }
};

export default reducer;
