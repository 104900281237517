import * as actionTypes from './actionTypes';
import { CertificateTemplateActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { ListResults } from '../../common/List/List';
import {
  CertificateTemplate,
  MappedSectionCertificateTemplates,
} from '../../domain/CertificateTemplate';
import _ from 'lodash';

export type CertificateTemplatesStateType = {
  certificateTemplatesListError: HttpError;
  certificateTemplatesList: ListResults<CertificateTemplate> | null;
  certificateTemplatesListLoading: boolean;
  certificateTemplateError: HttpError;
  certificateTemplate: CertificateTemplate | null;
  certificateTemplateLoading: boolean;
  saveCertificateTemplateLoading: boolean;
  saveCertificateTemplateError: HttpError;
  certificateTemplatesListUpdateNeeded: boolean;
  certificateTemplateSuggestions: CertificateTemplate[] | null;
  certificateTemplateSuggestionsLoading: boolean;
  certificateTemplateSuggestionsError: HttpError;
  saveCertificateThumbnailLoading: boolean;
  saveCertificateThumbnailError: HttpError;
  createCertificateNumbersLoading: boolean;
  createCertificateNumbersError: HttpError;
  createCertificateNumbersSuccess: boolean;
  certificateTemplateDeleteLoading: boolean;
  certificateTemplateDeleteError: HttpError;
  draftCertificateTemplatesListError: HttpError;
  draftCertificateTemplatesList: ListResults<CertificateTemplate> | null;
  draftCertificateTemplatesListLoading: boolean;
  draftCertificateTemplatesListUpdateNeeded: boolean;
  certificateTemplateUpdateLoading: boolean;
  certificateTemplateUpdateError: HttpError;
  certificateTemplateUpdateSuccess: boolean;
  createCertificateTemplateCopyLoading: boolean;
  createCertificateTemplateCopyError: HttpError;
  toggleCertificateTemplateStatusLoading: boolean;
  toggleCertificateTemplateStatusError: HttpError;
  toggleCertificateTemplateStatusSuccess: boolean;
  unsectionedCertificatesLoading: boolean;
  unsectionedCertificatesError: HttpError;
  unsectionedCertificates: CertificateTemplate[];
  replaceCertificateImageLoading: boolean;
  replaceCertificateImageSuccess: boolean;
  replaceCertificateImageError: HttpError;
  isCertificateTemplateHasSignedOrSentCertificate: boolean | null;
  isCertificateTemplateHasSignedOrSentCertificateLoading: boolean;
  isCertificateTemplateHasSignedOrSentCertificateSuccess: boolean;
  isCertificateTemplateHasSignedOrSentCertificateError: HttpError;
  saveCertificateThumbnailSuccess: boolean;
  mappedSectionCertificateTemplates: MappedSectionCertificateTemplates[];
  sectionCertificateTemplates: ListResults<CertificateTemplate> | null;
  sectionCertificateTemplatesError: HttpError;
  sectionCertificateTemplatesLoading: boolean;
  sentCertificateTemplatesError: HttpError;
  sentCertificateTemplates: ListResults<CertificateTemplate> | null;
  sentCertificateTemplatesLoading: boolean;
};

export type CertificateTemplatesActionType = CertificateTemplatesStateType & {
  type: CertificateTemplateActionTypes;
};

export const initialState: CertificateTemplatesStateType = {
  certificateTemplatesListError: null,
  certificateTemplatesList: null,
  certificateTemplatesListLoading: true,
  certificateTemplatesListUpdateNeeded: false,
  certificateTemplateSuggestions: null,
  certificateTemplateSuggestionsLoading: false,
  certificateTemplateSuggestionsError: null,
  draftCertificateTemplatesListError: null,
  draftCertificateTemplatesList: null,
  draftCertificateTemplatesListLoading: true,
  draftCertificateTemplatesListUpdateNeeded: false,
  certificateTemplateError: null,
  certificateTemplateLoading: false,
  saveCertificateTemplateError: null,
  saveCertificateTemplateLoading: false,
  certificateTemplate: null,
  saveCertificateThumbnailError: null,
  saveCertificateThumbnailLoading: false,
  createCertificateNumbersLoading: false,
  createCertificateNumbersError: null,
  createCertificateNumbersSuccess: false,
  certificateTemplateDeleteLoading: false,
  certificateTemplateDeleteError: null,
  certificateTemplateUpdateError: null,
  certificateTemplateUpdateLoading: false,
  certificateTemplateUpdateSuccess: false,
  createCertificateTemplateCopyLoading: false,
  createCertificateTemplateCopyError: null,
  toggleCertificateTemplateStatusLoading: false,
  toggleCertificateTemplateStatusError: null,
  toggleCertificateTemplateStatusSuccess: false,
  unsectionedCertificatesLoading: false,
  unsectionedCertificatesError: null,
  unsectionedCertificates: [],
  replaceCertificateImageLoading: false,
  replaceCertificateImageSuccess: false,
  replaceCertificateImageError: null,
  isCertificateTemplateHasSignedOrSentCertificate: null,
  isCertificateTemplateHasSignedOrSentCertificateSuccess: false,
  isCertificateTemplateHasSignedOrSentCertificateLoading: false,
  isCertificateTemplateHasSignedOrSentCertificateError: null,
  saveCertificateThumbnailSuccess: false,
  mappedSectionCertificateTemplates: [],
  sectionCertificateTemplates: null,
  sectionCertificateTemplatesError: null,
  sectionCertificateTemplatesLoading: false,
  sentCertificateTemplatesError: null,
  sentCertificateTemplates: null,
  sentCertificateTemplatesLoading: true,
};

const fetchCertificateTemplatesStart = (
  state: CertificateTemplatesStateType,
): CertificateTemplatesStateType => ({
  ...state,
  certificateTemplatesListLoading: true,
});

const fetchCertificateTemplatesSuccess = (
  state: CertificateTemplatesStateType,
  action: CertificateTemplatesActionType,
): CertificateTemplatesStateType => ({
  ...state,
  certificateTemplatesList: action.certificateTemplatesList,
  certificateTemplatesListLoading: false,
  certificateTemplatesListError: null,
  certificateTemplatesListUpdateNeeded: false,
  saveCertificateThumbnailSuccess: false,
});

const fetchCertificateTemplatesFail = (
  state: CertificateTemplatesStateType,
  action: CertificateTemplatesActionType,
): CertificateTemplatesStateType => ({
  ...state,
  certificateTemplatesListError: action.certificateTemplatesListError,
  certificateTemplatesListLoading: false,
});

const fetchDraftCertificateTemplatesStart = (
  state: CertificateTemplatesStateType,
): CertificateTemplatesStateType => ({
  ...state,
  draftCertificateTemplatesListLoading: true,
});

const fetchDraftCertificateTemplatesSuccess = (
  state: CertificateTemplatesStateType,
  action: CertificateTemplatesActionType,
): CertificateTemplatesStateType => ({
  ...state,
  draftCertificateTemplatesList: action.draftCertificateTemplatesList,
  draftCertificateTemplatesListLoading: false,
  draftCertificateTemplatesListError: null,
  draftCertificateTemplatesListUpdateNeeded: false,
});

const fetchDraftCertificateTemplatesFail = (
  state: CertificateTemplatesStateType,
  action: CertificateTemplatesActionType,
): CertificateTemplatesStateType => ({
  ...state,
  draftCertificateTemplatesListError: action.draftCertificateTemplatesListError,
  draftCertificateTemplatesListLoading: false,
});

const fetchCertificateTemplateStart = (
  state: CertificateTemplatesStateType,
): CertificateTemplatesStateType => ({
  ...state,
  certificateTemplateLoading: true,
});

const fetchCertificateTemplateSuccess = (
  state: CertificateTemplatesStateType,
  action: CertificateTemplatesActionType,
): CertificateTemplatesStateType => ({
  ...state,
  certificateTemplate: action.certificateTemplate,
  certificateTemplateLoading: false,
  certificateTemplateError: null,
});

const fetchCertificateTemplateFail = (
  state: CertificateTemplatesStateType,
  action: CertificateTemplatesActionType,
): CertificateTemplatesStateType => ({
  ...state,
  certificateTemplateError: action.certificateTemplateError,
  certificateTemplateLoading: false,
});

const fetchIsCertificateTemplateHasSignedOrSentCertificateStart = (
  state: CertificateTemplatesStateType,
): CertificateTemplatesStateType => ({
  ...state,
  isCertificateTemplateHasSignedOrSentCertificateLoading: true,
});

const fetchIsCertificateTemplateHasSignedOrSentCertificateSuccess = (
  state: CertificateTemplatesStateType,
  action: CertificateTemplatesActionType,
): CertificateTemplatesStateType => ({
  ...state,
  isCertificateTemplateHasSignedOrSentCertificate:
    action.isCertificateTemplateHasSignedOrSentCertificate,
  isCertificateTemplateHasSignedOrSentCertificateSuccess: true,
  isCertificateTemplateHasSignedOrSentCertificateLoading: false,
  isCertificateTemplateHasSignedOrSentCertificateError: null,
});

const fetchIsCertificateTemplateHasSignedOrSentCertificateFail = (
  state: CertificateTemplatesStateType,
  action: CertificateTemplatesActionType,
): CertificateTemplatesStateType => ({
  ...state,
  isCertificateTemplateHasSignedOrSentCertificateError:
    action.isCertificateTemplateHasSignedOrSentCertificateError,
  isCertificateTemplateHasSignedOrSentCertificateLoading: false,
});

const fetchSentCertificateTemplatesStart = (
  state: CertificateTemplatesStateType,
): CertificateTemplatesStateType => ({
  ...state,
  sentCertificateTemplatesLoading: true,
});

const fetchSentCertificateTemplatesSuccess = (
  state: CertificateTemplatesStateType,
  action: CertificateTemplatesActionType,
): CertificateTemplatesStateType => ({
  ...state,
  sentCertificateTemplates: action.sentCertificateTemplates,
  sentCertificateTemplatesLoading: false,
  sentCertificateTemplatesError: null,
});

const fetchSentCertificateTemplatesFail = (
  state: CertificateTemplatesStateType,
  action: CertificateTemplatesActionType,
): CertificateTemplatesStateType => ({
  ...state,
  sentCertificateTemplatesError: action.sentCertificateTemplatesError,
  sentCertificateTemplatesLoading: false,
});

const fetchCertificateTemplateSuggestionsStart = (
  state: CertificateTemplatesStateType,
): CertificateTemplatesStateType => ({
  ...state,
  certificateTemplateSuggestionsLoading: true,
});

const fetchCertificateTemplateSuggestionsSuccess = (
  state: CertificateTemplatesStateType,
  action: CertificateTemplatesActionType,
): CertificateTemplatesStateType => ({
  ...state,
  certificateTemplateSuggestions: action.certificateTemplateSuggestions,
  certificateTemplateSuggestionsLoading: false,
  certificateTemplateSuggestionsError: null,
});

const fetchCertificateTemplateSuggestionsFail = (
  state: CertificateTemplatesStateType,
  action: CertificateTemplatesActionType,
): CertificateTemplatesStateType => ({
  ...state,
  certificateTemplateSuggestionsError:
    action.certificateTemplateSuggestionsError,
  certificateTemplateSuggestionsLoading: false,
});

const resetCertificateTemplateSuggestions = (
  state: CertificateTemplatesStateType,
): CertificateTemplatesStateType => ({
  ...state,
  certificateTemplateSuggestions: null,
  certificateTemplateSuggestionsLoading: false,
  certificateTemplateSuggestionsError: null,
});

const saveCertificateTemplateStart = (
  state: CertificateTemplatesStateType,
): CertificateTemplatesStateType => ({
  ...state,
  saveCertificateTemplateLoading: true,
});

const saveCertificateTemplateSuccess = (
  state: CertificateTemplatesStateType,
  action: CertificateTemplatesActionType,
): CertificateTemplatesStateType => ({
  ...state,
  certificateTemplate: action.certificateTemplate,
  saveCertificateTemplateLoading: false,
  saveCertificateTemplateError: null,
});

const saveCertificateTemplateFail = (
  state: CertificateTemplatesStateType,
  action: CertificateTemplatesActionType,
): CertificateTemplatesStateType => ({
  ...state,
  saveCertificateTemplateError: action.saveCertificateTemplateError,
  saveCertificateTemplateLoading: false,
});

const saveCertificateTemplateThumbnailStart = (
  state: CertificateTemplatesStateType,
): CertificateTemplatesStateType => ({
  ...state,
  saveCertificateThumbnailLoading: true,
});

const saveCertificateTemplateThumbnailSuccess = (
  state: CertificateTemplatesStateType,
  action: CertificateTemplatesActionType,
): CertificateTemplatesStateType => ({
  ...state,
  saveCertificateThumbnailLoading: false,
  saveCertificateThumbnailError: null,
  saveCertificateThumbnailSuccess: true,
  certificateTemplate: action.certificateTemplate,
});

const saveCertificateTemplateThumbnailFail = (
  state: CertificateTemplatesStateType,
  action: CertificateTemplatesActionType,
): CertificateTemplatesStateType => ({
  ...state,
  saveCertificateThumbnailError: action.saveCertificateThumbnailError,
  saveCertificateThumbnailLoading: false,
  saveCertificateThumbnailSuccess: false,
});

const createCertificateNumbersStart = (
  state: CertificateTemplatesStateType,
): CertificateTemplatesStateType => ({
  ...state,
  createCertificateNumbersLoading: true,
});

const createCertificateNumbersSuccess = (
  state: CertificateTemplatesStateType,
  action: CertificateTemplatesActionType,
): CertificateTemplatesStateType => ({
  ...state,
  createCertificateNumbersLoading: false,
  createCertificateNumbersError: null,
  createCertificateNumbersSuccess: true,
  certificateTemplate: action.certificateTemplate,
});

const createCertificateNumbersFail = (
  state: CertificateTemplatesStateType,
  action: CertificateTemplatesActionType,
) => ({
  ...state,
  createCertificateNumbersLoading: false,
  createCertificateNumbersError: action.createCertificateNumbersError,
});

const deleteCertificateTemplateStart = (
  state: CertificateTemplatesStateType,
): CertificateTemplatesStateType => ({
  ...state,
  certificateTemplateDeleteLoading: true,
});

const deleteCertificateTemplateSuccess = (
  state: CertificateTemplatesStateType,
): CertificateTemplatesStateType => ({
  ...state,
  certificateTemplateDeleteLoading: false,
  certificateTemplateDeleteError: null,
  certificateTemplatesListUpdateNeeded: true,
  draftCertificateTemplatesListUpdateNeeded: true,
});

const deleteCertificateTemplateFail = (
  state: CertificateTemplatesStateType,
  action: CertificateTemplatesActionType,
): CertificateTemplatesStateType => ({
  ...state,
  certificateTemplateDeleteError: action.certificateTemplateDeleteError,
  certificateTemplateDeleteLoading: false,
});

const updateCertificateTemplateStart = (
  state: CertificateTemplatesStateType,
): CertificateTemplatesStateType => ({
  ...state,
  certificateTemplateUpdateLoading: true,
});

const updateCertificateTemplateSuccess = (
  state: CertificateTemplatesStateType,
): CertificateTemplatesStateType => ({
  ...state,
  certificateTemplateUpdateLoading: false,
  certificateTemplateUpdateError: null,
  certificateTemplateUpdateSuccess: true,
  certificateTemplatesListUpdateNeeded: true,
  draftCertificateTemplatesListUpdateNeeded: true,
});

const updateCertificateTemplateFail = (
  state: CertificateTemplatesStateType,
  action: CertificateTemplatesActionType,
): CertificateTemplatesStateType => ({
  ...state,
  certificateTemplateUpdateLoading: false,
  certificateTemplateUpdateError: action.certificateTemplateUpdateError,
});

const createCertificateTemplateCopyStart = (
  state: CertificateTemplatesStateType,
): CertificateTemplatesStateType => ({
  ...state,
  createCertificateTemplateCopyLoading: true,
});

const createCertificateTemplateCopySuccess = (
  state: CertificateTemplatesStateType,
  action: CertificateTemplatesActionType,
): CertificateTemplatesStateType => ({
  ...state,
  createCertificateTemplateCopyLoading: false,
  createCertificateTemplateCopyError: null,
  certificateTemplatesListUpdateNeeded: true,
  draftCertificateTemplatesListUpdateNeeded: true,
});

const createCertificateTemplateCopyFail = (
  state: CertificateTemplatesStateType,
  action: CertificateTemplatesActionType,
): CertificateTemplatesStateType => ({
  ...state,
  createCertificateTemplateCopyError: action.createCertificateTemplateCopyError,
  createCertificateTemplateCopyLoading: false,
});

const toggleCertificateTemplateStatusStart = (
  state: CertificateTemplatesStateType,
): CertificateTemplatesStateType => ({
  ...state,
  toggleCertificateTemplateStatusLoading: true,
});

const toggleCertificateTemplateStatusSuccess = (
  state: CertificateTemplatesStateType,
): CertificateTemplatesStateType => ({
  ...state,
  toggleCertificateTemplateStatusLoading: false,
  toggleCertificateTemplateStatusError: null,
  toggleCertificateTemplateStatusSuccess: true,
  certificateTemplatesListUpdateNeeded: true,
  draftCertificateTemplatesListUpdateNeeded: true,
});

const toggleCertificateTemplateStatusFail = (
  state: CertificateTemplatesStateType,
  action: CertificateTemplatesActionType,
): CertificateTemplatesStateType => ({
  ...state,
  toggleCertificateTemplateStatusLoading: false,
  toggleCertificateTemplateStatusError:
    action.toggleCertificateTemplateStatusError,
});

const fetchUnsectionedCertificatesStart = (
  state: CertificateTemplatesStateType,
): CertificateTemplatesStateType => ({
  ...state,
  unsectionedCertificatesLoading: true,
});

const fetchUnsectionedCertificatesSuccess = (
  state: CertificateTemplatesStateType,
  action: CertificateTemplatesActionType,
): CertificateTemplatesStateType => ({
  ...state,
  unsectionedCertificates: action.unsectionedCertificates,
  unsectionedCertificatesLoading: false,
  unsectionedCertificatesError: null,
});

const fetchUnsectionedCertificatesFail = (
  state: CertificateTemplatesStateType,
  action: CertificateTemplatesActionType,
): CertificateTemplatesStateType => ({
  ...state,
  unsectionedCertificatesError: action.unsectionedCertificatesError,
  unsectionedCertificatesLoading: false,
});

const fetchSectionCertificateTemplatesStart = (
  state: CertificateTemplatesStateType,
): CertificateTemplatesStateType => ({
  ...state,
  sectionCertificateTemplatesLoading: true,
});

const fetchSectionCertificateTemplatesSuccess = (
  state: CertificateTemplatesStateType,
  action: CertificateTemplatesActionType,
): CertificateTemplatesStateType => {
  const prev = state.mappedSectionCertificateTemplates?.length
    ? state.mappedSectionCertificateTemplates
    : [];

  const arr = action.sectionCertificateTemplates?.result?.length
    ? [
        ...prev,
        {
          sectionId: Number(
            action.sectionCertificateTemplates.result[0].certificateSectionId,
          ),
          certificateTemplates: action.sectionCertificateTemplates.result ?? [],
        },
      ]
    : prev;

  const mappedSectionCertificateTemplates = _(arr)
    .keyBy('sectionId')
    .values()
    .value();

  return {
    ...state,
    sectionCertificateTemplates: action.sectionCertificateTemplates,
    mappedSectionCertificateTemplates: mappedSectionCertificateTemplates,
    sectionCertificateTemplatesLoading: false,
    sectionCertificateTemplatesError: null,
  };
};

const fetchSectionCertificateTemplatesFail = (
  state: CertificateTemplatesStateType,
  action: CertificateTemplatesActionType,
): CertificateTemplatesStateType => ({
  ...state,
  sectionCertificateTemplatesError: action.sectionCertificateTemplatesError,
  sectionCertificateTemplatesLoading: false,
});

const replaceCertificateImageStart = (
  state: CertificateTemplatesStateType,
): CertificateTemplatesStateType => ({
  ...state,
  replaceCertificateImageLoading: true,
});

const replaceCertificateImageSuccess = (
  state: CertificateTemplatesStateType,
  action: CertificateTemplatesActionType,
): CertificateTemplatesStateType => ({
  ...state,
  replaceCertificateImageLoading: false,
  replaceCertificateImageSuccess: true,
  replaceCertificateImageError: null,
  certificateTemplate: action.certificateTemplate,
});

const replaceCertificateImageFail = (
  state: CertificateTemplatesStateType,
  action: CertificateTemplatesActionType,
): CertificateTemplatesStateType => ({
  ...state,
  replaceCertificateImageError: action.replaceCertificateImageError,
  replaceCertificateImageLoading: false,
});

const resetIsCertificateTemplateHasSignedOrSentCertificateStore = (
  state: CertificateTemplatesStateType,
): CertificateTemplatesStateType => ({
  ...state,
  isCertificateTemplateHasSignedOrSentCertificate: null,
  isCertificateTemplateHasSignedOrSentCertificateLoading: false,
  isCertificateTemplateHasSignedOrSentCertificateError: null,
  isCertificateTemplateHasSignedOrSentCertificateSuccess: false,
});

const resetCropImageStore = (
  state: CertificateTemplatesStateType,
): CertificateTemplatesStateType => ({
  ...state,
  replaceCertificateImageLoading: false,
  replaceCertificateImageError: null,
  replaceCertificateImageSuccess: false,
});

const resetCertificateTemplateStore = (): CertificateTemplatesStateType => ({
  ...initialState,
});

const resetCertificateNumberStore = (
  state: CertificateTemplatesStateType,
): CertificateTemplatesStateType => ({
  ...state,
  createCertificateNumbersLoading: false,
  createCertificateNumbersError: null,
  createCertificateNumbersSuccess: false,
});

const resetCertificateTemplateUpdateStore = (
  state: CertificateTemplatesStateType,
): CertificateTemplatesStateType => ({
  ...state,
  certificateTemplateUpdateLoading: false,
  certificateTemplateUpdateError: null,
  certificateTemplateUpdateSuccess: false,
});

const completelyResetCertificateTemplateStore =
  (): CertificateTemplatesStateType => ({
    ...initialState,
  });

const reducer = (
  state = initialState,
  action: CertificateTemplatesActionType,
) => {
  switch (action.type) {
    case actionTypes.FETCH_CERTIFICATE_TEMPLATES_START:
      return fetchCertificateTemplatesStart(state);
    case actionTypes.FETCH_CERTIFICATE_TEMPLATES_SUCCESS:
      return fetchCertificateTemplatesSuccess(state, action);
    case actionTypes.FETCH_CERTIFICATE_TEMPLATES_FAIL:
      return fetchCertificateTemplatesFail(state, action);
    case actionTypes.FETCH_DRAFT_CERTIFICATE_TEMPLATES_START:
      return fetchDraftCertificateTemplatesStart(state);
    case actionTypes.FETCH_DRAFT_CERTIFICATE_TEMPLATES_SUCCESS:
      return fetchDraftCertificateTemplatesSuccess(state, action);
    case actionTypes.FETCH_DRAFT_CERTIFICATE_TEMPLATES_FAIL:
      return fetchDraftCertificateTemplatesFail(state, action);
    case actionTypes.FETCH_CERTIFICATE_TEMPLATE_START:
      return fetchCertificateTemplateStart(state);
    case actionTypes.FETCH_CERTIFICATE_TEMPLATE_SUCCESS:
      return fetchCertificateTemplateSuccess(state, action);
    case actionTypes.FETCH_CERTIFICATE_TEMPLATE_FAIL:
      return fetchCertificateTemplateFail(state, action);
    case actionTypes.FETCH_IS_CERTIFICATE_TEMPLATE_HAS_SIGNED_OR_SENT_CERTIFICATE_START:
      return fetchIsCertificateTemplateHasSignedOrSentCertificateStart(state);
    case actionTypes.FETCH_IS_CERTIFICATE_TEMPLATE_HAS_SIGNED_OR_SENT_CERTIFICATE_SUCCESS:
      return fetchIsCertificateTemplateHasSignedOrSentCertificateSuccess(
        state,
        action,
      );
    case actionTypes.FETCH_IS_CERTIFICATE_TEMPLATE_HAS_SIGNED_OR_SENT_CERTIFICATE_FAIL:
      return fetchIsCertificateTemplateHasSignedOrSentCertificateFail(
        state,
        action,
      );
    case actionTypes.FETCH_CERTIFICATE_TEMPLATE_SUGGESTIONS_START:
      return fetchCertificateTemplateSuggestionsStart(state);
    case actionTypes.FETCH_CERTIFICATE_TEMPLATE_SUGGESTIONS_SUCCESS:
      return fetchCertificateTemplateSuggestionsSuccess(state, action);
    case actionTypes.FETCH_CERTIFICATE_TEMPLATE_SUGGESTIONS_FAIL:
      return fetchCertificateTemplateSuggestionsFail(state, action);
    case actionTypes.RESET_CERTIFICATE_TEMPLATE_SUGGESTIONS:
      return resetCertificateTemplateSuggestions(action);
    case actionTypes.SAVE_CERTIFICATE_TEMPLATE_START:
      return saveCertificateTemplateStart(state);
    case actionTypes.SAVE_CERTIFICATE_TEMPLATE_SUCCESS:
      return saveCertificateTemplateSuccess(state, action);
    case actionTypes.SAVE_CERTIFICATE_TEMPLATE_FAIL:
      return saveCertificateTemplateFail(state, action);
    case actionTypes.SAVE_CERTIFICATE_TEMPLATE_THUMBNAIL_START:
      return saveCertificateTemplateThumbnailStart(state);
    case actionTypes.SAVE_CERTIFICATE_TEMPLATE_THUMBNAIL_SUCCESS:
      return saveCertificateTemplateThumbnailSuccess(state, action);
    case actionTypes.SAVE_CERTIFICATE_TEMPLATE_THUMBNAIL_FAIL:
      return saveCertificateTemplateThumbnailFail(state, action);
    case actionTypes.CREATE_CERTIFICATE_NUMBERS_START:
      return createCertificateNumbersStart(state);
    case actionTypes.CREATE_CERTIFICATE_NUMBERS_SUCCESS:
      return createCertificateNumbersSuccess(state, action);
    case actionTypes.CREATE_CERTIFICATE_NUMBERS_FAIL:
      return createCertificateNumbersFail(state, action);
    case actionTypes.DELETE_CERTIFICATE_TEMPLATE_START:
      return deleteCertificateTemplateStart(state);
    case actionTypes.DELETE_CERTIFICATE_TEMPLATE_SUCCESS:
      return deleteCertificateTemplateSuccess(state);
    case actionTypes.DELETE_CERTIFICATE_TEMPLATE_FAIL:
      return deleteCertificateTemplateFail(state, action);
    case actionTypes.UPDATE_CERTIFICATE_TEMPLATE_START:
      return updateCertificateTemplateStart(state);
    case actionTypes.UPDATE_CERTIFICATE_TEMPLATE_SUCCESS:
      return updateCertificateTemplateSuccess(state);
    case actionTypes.UPDATE_CERTIFICATE_TEMPLATE_FAIL:
      return updateCertificateTemplateFail(state, action);
    case actionTypes.CREATE_CERTIFICATE_TEMPLATE_COPY_START:
      return createCertificateTemplateCopyStart(state);
    case actionTypes.CREATE_CERTIFICATE_TEMPLATE_COPY_SUCCESS:
      return createCertificateTemplateCopySuccess(state, action);
    case actionTypes.CREATE_CERTIFICATE_TEMPLATE_COPY_FAIL:
      return createCertificateTemplateCopyFail(state, action);
    case actionTypes.TOGGLE_CERTIFICATE_TEMPLATE_STATUS_START:
      return toggleCertificateTemplateStatusStart(state);
    case actionTypes.TOGGLE_CERTIFICATE_TEMPLATE_STATUS_SUCCESS:
      return toggleCertificateTemplateStatusSuccess(state);
    case actionTypes.TOGGLE_CERTIFICATE_TEMPLATE_STATUS_FAIL:
      return toggleCertificateTemplateStatusFail(state, action);
    case actionTypes.FETCH_UNSECTIONED_CERTIFICATES_START:
      return fetchUnsectionedCertificatesStart(state);
    case actionTypes.FETCH_UNSECTIONED_CERTIFICATES_SUCCESS:
      return fetchUnsectionedCertificatesSuccess(state, action);
    case actionTypes.FETCH_UNSECTIONED_CERTIFICATES_FAIL:
      return fetchUnsectionedCertificatesFail(state, action);
    case actionTypes.FETCH_SECTION_CERTIFICATE_TEMPLATES_START:
      return fetchSectionCertificateTemplatesStart(state);
    case actionTypes.FETCH_SECTION_CERTIFICATE_TEMPLATES_SUCCESS:
      return fetchSectionCertificateTemplatesSuccess(state, action);
    case actionTypes.FETCH_SECTION_CERTIFICATE_TEMPLATES_FAIL:
      return fetchSectionCertificateTemplatesFail(state, action);
    case actionTypes.FETCH_SENT_CERTIFICATE_TEMPLATES_START:
      return fetchSentCertificateTemplatesStart(state);
    case actionTypes.FETCH_SENT_CERTIFICATE_TEMPLATES_SUCCESS:
      return fetchSentCertificateTemplatesSuccess(state, action);
    case actionTypes.FETCH_SENT_CERTIFICATE_TEMPLATES_FAIL:
      return fetchSentCertificateTemplatesFail(state, action);
    case actionTypes.REPLACE_CERTIFICATE_IMAGE_START:
      return replaceCertificateImageStart(state);
    case actionTypes.REPLACE_CERTIFICATE_IMAGE_SUCCESS:
      return replaceCertificateImageSuccess(state, action);
    case actionTypes.REPLACE_CERTIFICATE_IMAGE_FAIL:
      return replaceCertificateImageFail(state, action);
    case actionTypes.RESET_IS_CERTIFICATE_TEMPLATE_HAS_SIGNED_OR_SENT_CERTIFICATE_STORE:
      return resetIsCertificateTemplateHasSignedOrSentCertificateStore(state);
    case actionTypes.RESET_CROP_IMAGE_STORE:
      return resetCropImageStore(state);
    case actionTypes.RESET_CERTIFICATE_TEMPLATE_STORE:
      return resetCertificateTemplateStore();
    case actionTypes.RESET_CERTIFICATE_TEMPLATE_UPDATE_STORE:
      return resetCertificateTemplateUpdateStore(state);
    case actionTypes.RESET_CERTIFICATE_NUMBER_STORE:
      return resetCertificateNumberStore(state);
    case actionTypes.LOGOUT:
      return completelyResetCertificateTemplateStore();
    default:
      return state;
  }
};

export default reducer;
