import React, { ReactNode, useState, useEffect } from 'react';
import styles from './Layout.module.scss';
import Sidebar from '../Sidebar/Sidebar';
import {
  NavLink,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import cx from 'classnames';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import useWindowSize from '../../hooks/useWindowSize/useWindowSize';
import Navigation from '../Navigation/Navigation';
import {
  faUsers,
  faLanguage,
  faMoneyCheckDollar,
  faObjectGroup,
  faGlobe,
  faUserGroup,
  faMoneyBill,
} from '@fortawesome/free-solid-svg-icons';
import { routes } from '../../config/Router/routes';
import { useIntl } from 'react-intl';
import { translate } from '../../utility/messageTranslator/translate';
import { StoreState } from '../../config/StoreProvider/StoreProvider';
import { connect } from 'react-redux';
import { User } from '../../domain/User';
import { Roles } from '../../domain/Role';
import PublicLayout from './PublicLayout/PublicLayout';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import {
  resetPasswordResetStore,
  setPostLoginUrl,
} from '../../store/auth/actions';
import SuccessModal from '../SuccessModal/SuccessModal';
import * as userService from '../../store/user/service';

export type Props = {
  children: ReactNode;
  currentUser: User | null;
  isAuthenticated: boolean;
  onSetPostLoginUrl: () => void;
  postLoginUrl: string | null;
  resetPasswordSuccess: boolean;
  onResetPasswordResetStore: () => void;
  onFetchCurrentUser: () => void;
  isUserRefreshNeeded: boolean;
};

export type NavigationItem = {
  label: string;
  to: string;
  icon?: IconProp;
};

export type NavigationGroup = {
  label: string;
  items: NavigationItem[];
};

const MOBILE_BREAK_POINT = 900;

const IGNORED_ROUTES = [routes.templates.form.replace(':id?', '')];

const Layout = ({
  children,
  currentUser,
  isAuthenticated,
  onSetPostLoginUrl,
  postLoginUrl,
  resetPasswordSuccess,
  onResetPasswordResetStore,
  onFetchCurrentUser,
  isUserRefreshNeeded,
}: Props) => {
  const [, setSearchParams] = useSearchParams();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPaymentSuccess, setIsPaymentSuccess] = useState(false);
  const [amount, setAmount] = useState(0);
  const location = useLocation();
  const intl = useIntl();
  const navigate = useNavigate();

  const params = new URLSearchParams(window.location.search);

  useEffect(() => {
    const successParam = params.get('success');
    const amountParam = params.get('amount');

    if (!successParam) {
      return;
    }

    setIsPaymentSuccess(successParam === 'true');
    setIsModalOpen(true);
    setAmount(Number(amountParam) ?? 0);
  }, [params]);

  const { width } = useWindowSize();

  useEffect(() => {
    setIsMobileMenuOpen(false);
  }, [location.key]);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (isModalOpen) {
      timer = setTimeout(() => {
        onFetchCurrentUser();
      }, 1000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [isModalOpen]);

  useEffect(() => {
    const timer = setTimeout(() => {
      onFetchCurrentUser();
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [isUserRefreshNeeded]);

  useEffect(() => {
    if (isAuthenticated && postLoginUrl) {
      navigate(`${postLoginUrl}?loggedIn=true`);
      onSetPostLoginUrl();
    }
  }, [isAuthenticated]);

  const SIDEBAR_ITEMS = [
    {
      label: translate(intl, 'NAVIGATION.GROUP_GLOBAL'),
      items: [
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_PAYMENTS'),
          to: routes.payments.list,
          icon: faMoneyCheckDollar as IconProp,
          roles: [Roles.ADMIN],
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_TEMPLATES'),
          to: routes.templates.list,
          icon: faObjectGroup as IconProp,
          roles: [Roles.ADMIN],
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_ADMINS'),
          icon: faUserGroup as IconProp,
          to: routes.admins.list,
          roles: [Roles.ADMIN],
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_PARTICIPANTS'),
          to: routes.users.list,
          icon: faUsers as IconProp,
          roles: [Roles.ADMIN],
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_ORGANIZERS'),
          to: routes.organizers.list,
          icon: faUsers as IconProp,
          roles: [Roles.ADMIN],
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_TRANSLATIONS'),
          to: routes.translations.list,
          icon: faGlobe as IconProp,
          roles: [Roles.ADMIN],
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_LANGUAGES'),
          to: routes.languages,
          icon: faLanguage as IconProp,
          roles: [Roles.ADMIN],
        },
        {
          label: translate(intl, 'NAVIGATION.CREDIT_PACKAGES'),
          to: routes.creditPackages.list,
          icon: faMoneyBill as IconProp,
          roles: [Roles.ADMIN],
        },
      ],
    },
  ];

  useEffect(() => {
    if (isMobileMenuOpen) {
      window.scroll({ top: 0 });
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isMobileMenuOpen]);

  const getNavigationGroups = () => {
    if (currentUser?.profile?.selectedRole === Roles.OWNER) {
      return [];
    }

    const sidebarItems = SIDEBAR_ITEMS.map((sidebarItemGroup) => ({
      label: sidebarItemGroup.label,
      items: sidebarItemGroup.items.filter((sidebarItem) => {
        return (
          !!currentUser?.profile?.selectedRole &&
          sidebarItem.roles.includes(currentUser.role)
        );
      }),
    }));

    return sidebarItems;
  };

  const handleModalClose = () => {
    onResetPasswordResetStore();
  };

  const handlePaymentConfirmationClose = () => {
    setIsModalOpen(false);
    setSearchParams((params) => {
      params.delete('success');
      params.delete('amount');

      return params;
    });
    setIsPaymentSuccess(false);
    setAmount(0);
  };

  const renderPaymentConfirmationModal = () => {
    return (
      isModalOpen && (
        <SuccessModal
          onClose={handlePaymentConfirmationClose}
          isOpen={isModalOpen}
          title={translate(
            intl,
            isPaymentSuccess ? 'PAYMENT.SUCCESS_TITLE' : 'PAYMENT.FAIL_TITLE',
          )}
          description={translate(
            intl,
            isPaymentSuccess
              ? 'PAYMENT.SUCCESS_DESCRIPTION'
              : 'PAYMENT.FAIL_DESCRIPTION',
          ).replace(':credits', amount.toString())}
          buttonText={translate(intl, 'PAYMENT.SUCCESS_MODAL_BUTTON')}
          isSuccess={isPaymentSuccess}
        />
      )
    );
  };

  const isRouteIgnored = IGNORED_ROUTES.find((ignoredRoute) =>
    location.pathname.includes(ignoredRoute),
  );

  if (!location.pathname.includes(routes.admin) || isRouteIgnored) {
    return (
      <PublicLayout>
        {children}
        <SuccessModal
          onClose={handleModalClose}
          isOpen={resetPasswordSuccess}
          title={translate(intl, 'SUCCESS_MODAL.PASSWORD_RESET_SUCCESS_TITLE')}
          buttonText={translate(
            intl,
            'SUCCESS_MODAL.PASSWORD_RESET_SUCCESS_BUTTON',
          )}
        />
        {renderPaymentConfirmationModal()}
      </PublicLayout>
    );
  }

  return (
    <>
      <div className={styles.container}>
        {width && width >= MOBILE_BREAK_POINT && (
          <Sidebar navigationGroups={getNavigationGroups()} />
        )}
        <div
          className={cx(styles.rightSection, {
            [styles.noScroll]: isMobileMenuOpen,
          })}
        >
          <Navigation
            onDrawerClick={() => setIsMobileMenuOpen((prev) => !prev)}
            isMobileMenuOpen={isMobileMenuOpen}
          />
          <div className={styles.content}>{children}</div>
        </div>
      </div>
      {isMobileMenuOpen && width && width < MOBILE_BREAK_POINT && (
        <div className={styles.mobileDrawer}>
          {getNavigationGroups().map((navigationGroup) => (
            <div className={styles.navigationGroup} key={navigationGroup.label}>
              <div className={styles.groupName}>{navigationGroup.label}</div>
              {navigationGroup.items.map((item) => (
                <NavLink
                  key={item.label}
                  to={item.to}
                  className={({ isActive }) =>
                    cx(styles.navigationItem, {
                      [styles.activeSubItem]: isActive,
                    })
                  }
                >
                  {item.label}
                </NavLink>
              ))}
            </div>
          ))}
        </div>
      )}
      {renderPaymentConfirmationModal()}
    </>
  );
};

const mapStateToProps = (state: StoreState) => ({
  currentUser: state.user.currentUser,
  isAuthenticated: state.auth.isAuthenticated,
  postLoginUrl: state.auth.postLoginUrl,
  resetPasswordSuccess: state.auth.resetPasswordSuccess,
  isUserRefreshNeeded: state.profile.setSelectedRoleSuccess,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onSetPostLoginUrl: () => dispatch(setPostLoginUrl(null)),
  onResetPasswordResetStore: () => dispatch(resetPasswordResetStore()),
  onFetchCurrentUser: () => dispatch(userService.fetchCurrentUser(true)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
