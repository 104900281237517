export const SELECT_COMPANY_START = 'SELECT_COMPANY_START';
export const SELECT_COMPANY_SUCCESS = 'SELECT_COMPANY_SUCCESS';
export const SELECT_COMPANY_FAIL = 'SELECT_COMPANY_FAIL';

export const INVITE_TO_COMPANY_START = 'INVITE_TO_COMPANY_START';
export const INVITE_TO_COMPANY_SUCCESS = 'INVITE_TO_COMPANY_SUCCESS';
export const INVITE_TO_COMPANY_FAIL = 'INVITE_TO_COMPANY_FAIL';

export const REMOVE_USER_FROM_COMPANY_START = 'REMOVE_USER_FROM_COMPANY_START';
export const REMOVE_USER_FROM_COMPANY_SUCCESS =
  'REMOVE_USER_FROM_COMPANY_SUCCESS';
export const REMOVE_USER_FROM_COMPANY_FAIL = 'REMOVE_USER_FROM_COMPANY_FAIL';

export const FETCH_ALL_COMPANY_USERS_START = 'FETCH_ALL_COMPANY_USERS_START';
export const FETCH_ALL_COMPANY_USERS_SUCCESS =
  'FETCH_ALL_COMPANY_USERS_SUCCESS';
export const FETCH_ALL_COMPANY_USERS_FAIL = 'FETCH_ALL_COMPANY_USERS_FAIL';

export const SET_SELECTED_COMPANY = 'SET_SELECTED_COMPANY';

export const LOGOUT = 'LOGOUT';

export type CompanyActionTypes =
  | typeof SELECT_COMPANY_START
  | typeof SELECT_COMPANY_SUCCESS
  | typeof SELECT_COMPANY_FAIL
  | typeof INVITE_TO_COMPANY_START
  | typeof INVITE_TO_COMPANY_SUCCESS
  | typeof INVITE_TO_COMPANY_FAIL
  | typeof FETCH_ALL_COMPANY_USERS_START
  | typeof FETCH_ALL_COMPANY_USERS_SUCCESS
  | typeof FETCH_ALL_COMPANY_USERS_FAIL
  | typeof REMOVE_USER_FROM_COMPANY_START
  | typeof REMOVE_USER_FROM_COMPANY_SUCCESS
  | typeof REMOVE_USER_FROM_COMPANY_FAIL
  | typeof SET_SELECTED_COMPANY
  | typeof LOGOUT;
